import { Fragment, useEffect } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { groupBy, sortBy, omitBy, isUndefined, isEmpty, pick, omit, orderBy, flatten, uniq, keyBy } from 'lodash';
import { useToggle } from 'react-use';
import { isEmail, isMultibyte } from 'validator';
import qs from 'qs';
import { format as formatDate } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { fieldDisplayValue, ageDisplay } from '../../shared/util';
import { fields, blockSmsFields, adminFields, commentFields } from '../../shared/models/user';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import AddInTenantButton from '../AddInTenantButton';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import { updateUserStatus } from '../../lib/firebaseFunctions';

import TenantLink from '../TenantLink';
import { generateInquiryId } from '../../shared/util';

const updateUserEmail = functions.httpsCallable('updateUserEmail');
const db = firebase.firestore();
const usersRef = db.collection('users');
const ordersRef = db.collection('orders');
const troubleInquiriesRef = db.collection('troubleInquiries');
const methodInquiriesRef = db.collection('methodInquiries');
const inquiriesRef = db.collection('inquiries');
const inquiryTypesRef = db.collection('inquiryTypes');
const entriesRef = db.collectionGroup('entries');
const productsRef = db.collection('products');
const userTagsRef = db.collection('userTags');
const activitiesRef = db.collection('activities');
const { entries } = Object;
const emailFields = {
  email: {
    type: 'string',
    inputType: 'email',
    label: 'メールアドレス',
    validations: {
      required: (v) => !isEmpty(v),
      format: (v) => !v || isEmail(v),
      notMultibyte: (v) => !v || !isMultibyte(v),
    },
  },
};

export default AdminPage(function AdminUsers(props) {
  const { firebaseUser } = useFirebaseUser();
  const {
    user: currentUser,
    match: {
      params: { userId },
    },
    tenant,
  } = props;
  const [showsEmailFormModal, toggleEmailFormModal] = useToggle(false);
  const user = useDocumentSubscription(usersRef.doc(userId), [userId]);
  const tenantUser = useDocumentSubscription(tenant.ref.collection('tenantUsers').doc(userId), [userId]);
  const mainUser = useDocumentSubscription(user?.mainUserId && usersRef.doc(user.mainUserId), [user?.mainUserId]);
  const subUsers = useDocumentsFetch((user?.subUserIds || []).map(_ => usersRef.doc(_)), [user]);
  const pendingMainUser = useDocumentSubscription(user?.pendingMainUserId && usersRef.doc(user.pendingMainUserId), [user?.pendingMainUserId]);
  const pendingSubUsers = useCollectionSubscription(usersRef.where('tenantIds', 'array-contains', tenant.id).where('pendingMainUserId', '==', userId), [userId]);
  const { children = [] } = user || {};
  const orders = useCollectionSubscriptionInTenant(user && ordersRef.where('createdBy.uid', '==', user.uid), [user]);
  const referredEmail = useDocumentSubscription(user && db.collection('referredEmails').doc(user.email), [user]);
  const referredEmailQrUrl = useDocumentSubscription(referredEmail && db.collection('qrUrls').doc(referredEmail.qrUrlId), [referredEmail]);
  const orderedProductIds = uniq(flatten(orders.map(({ orderItems }) => orderItems.map(({ productId }) => productId))));
  const orderedProducts = useDocumentsFetch(
    orderedProductIds.map((_) => productsRef.doc(_)),
    [orders]
  );
  const orderedProductsById = keyBy(orderedProducts, 'id');
  const envelopeProducts = useCollectionSubscriptionInTenant(db.collection('envelopeProducts'));
  const envelopeProductsById = keyBy(envelopeProducts, 'id');
  const troubleInquiries = useCollectionSubscriptionInTenant(
    user && troubleInquiriesRef.where('createdBy.uid', '==', user.uid),
    [user]
  );
  const methodInquiries = useCollectionSubscriptionInTenant(
    user && methodInquiriesRef.where('createdBy.uid', '==', user.uid),
    [user]
  );
  const inquiries = useCollectionSubscriptionInTenant(user && inquiriesRef.where('createdBy.uid', '==', user.uid), [
    user,
  ]);
  const inquiryTypes = useCollectionSubscriptionInTenant(inquiryTypesRef);
  const inquiryTypesById = keyBy(inquiryTypes, 'id');
  const eventEntries = useCollectionSubscriptionInTenant(user && entriesRef.where('createdBy.uid', '==', user.uid), [
    user,
  ]);
  const events = useDocumentsFetch(
    eventEntries.map(({ ref }) => ref?.parent.parent),
    [eventEntries]
  );
  const eventsById = keyBy(events, 'id');
  const surveyAnswers = useCollectionSubscriptionInTenant(
    user && db.collection('surveyAnswers').where('createdBy.uid', '==', user.uid),
    [user]
  );
  const surveys = useDocumentsFetch(
    surveyAnswers.map((_) => db.collection('surveys').doc(_.surveyId)),
    [surveyAnswers]
  );
  const surveysById = keyBy(surveys, 'id');
  const commentsRef = usersRef.doc(userId).collection('userComments');
  const comments = sortBy(useCollectionSubscriptionInTenant(commentsRef, [userId]), (_) => _.createdAt.toDate());
  const userTags = sortBy(useCollectionSubscriptionInTenant(db.collection('userTags')), (_) => _.createdAt.toDate());
  const deliveryHistories = useCollectionSubscriptionInTenant(
    user && db.collectionGroup('deliveryTargets').where('uid', '==', user.uid).where('status', '==', 'delivered').orderBy('createdAt'),
    [user]
  );
  const deliveryHistoryParents = useDocumentsFetch(
    deliveryHistories.map((_) => _.ref.parent.parent),
    [deliveryHistories]
  );
  const deliveryHistoryParentsByPath = keyBy(deliveryHistoryParents, 'ref.path');
  const magazineGroups = sortBy(useCollectionSubscriptionInTenant(db.collection('magazineGroups')), (_) =>
    _.createdAt.toDate()
  );
  const magazineGroupsById = keyBy(magazineGroups, 'id');
  const finishSupportActivities = useCollectionSubscriptionInTenant(user && activitiesRef.where('payload.uid', '==', user.uid).orderBy('createdAt'), [user]);
  const accessLogs = useCollectionSubscriptionInTenant(
    user && db.collection('accessLogs').where('createdBy.uid', '==', user.uid).limit(1),
    [user]
  );

  const activities = orderBy(
    [
      ...(user != null ? [{ id: userId, createdAt: user.createdAt ?? ({ toDate: _ => new Date(1900, 0, 1) }), label: 'アカウント作成', description: user.createdAt == null && '(日時不明)', }] : []),
      ...orders.map((order) => {
        const { id, createdAt, orderItems } = order;
        const label = '商品購入';
        const description = `${orderItems.map(({ productId }) => orderedProductsById[productId]?.name).join(', ')}`;
        const queryParams = { field: ['id'], text: id };
        const path = `/admin/orders?${qs.stringify(queryParams)}`;

        return { id, createdAt, label, description, path };
      }),
      ...orders.filter(_ => _.cancelledAt != null).map((order) => {
        const { id, createdAt, orderItems, cancelledAt, } = order;
        const label = '商品購入キャンセル';
        const description = `${formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}購入分 (${orderItems.map(({ productId }) => orderedProductsById[productId]?.name).join(', ')})`;
        const queryParams = { field: ['id'], text: id };
        const path = `/admin/orders?${qs.stringify(queryParams)}`;

        return { id, createdAt: cancelledAt, label, description, path };
      }),
      ...troubleInquiries.map((troubleInquiry) => {
        const { id, createdAt } = troubleInquiry;
        const label = 'お問合せ';
        const description = '不具合・組立お問合せ';
        const path = `/admin/troubleInquiries/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...methodInquiries.map((methodInquiry) => {
        const { id, createdAt } = methodInquiry;
        const label = 'お問合せ';
        const description = '乗り方お問合せ';
        const path = `/admin/methodInquiries/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...inquiries.map((inquiry) => {
        const { id, createdAt, inquiryTypeId, isAdminCreated } = inquiry;
        const label = isAdminCreated ? 'お問合せ（管理者作成）' : 'お問合せ';
        const description = inquiryTypesById[inquiryTypeId]?.subject;
        const path = `/admin/inquiries/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...eventEntries.map((entry) => {
        const {
          id,
          createdAt,
          ref: {
            parent: {
              parent: { id: eventId },
            },
          },
        } = entry;
        const label = 'イベント申込';
        const description = eventsById[eventId]?.name;
        const path = `/admin/events/${eventId}/entries/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...eventEntries.filter(_ => _.cancelledAt != null).map((entry) => {
        const { id, ref, createdAt, cancelledAt, } = entry;
        const label = 'イベント申込キャンセル';
        const description = `${formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}申込み分 (${eventsById[ref.parent.parent.id]?.name})`;
        const path = `/admin/events/${ref.parent.parent.id}/entries/${id}`;

        return { id, createdAt: cancelledAt, label, description, path };
      }),
      ...surveyAnswers.map((surveyAnswer) => {
        const { id, surveyId, createdAt } = surveyAnswer;
        const label = 'アンケートページ回答';
        const description = surveysById[surveyId]?.title;
        const path = `/admin/surveyAnswers/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...deliveryHistories.map((deliveryHistory) => {
        const { id, ref, createdAt } = deliveryHistory;
        const deliveryHistoryParent = deliveryHistoryParentsByPath[ref.parent.parent.path];
        const { label, description } =
          {
            magazines: {
              label: 'SMS配信',
              description: deliveryHistoryParent?.body,
            },
            smsDeliveries: {
              label: 'SMS配信',
              description: deliveryHistoryParent?.emailSubject || deliveryHistoryParent?.body,
            },
            envelopeSchedules: {
              label: '郵便発送処理',
              description: (deliveryHistoryParent?.envelopeProductIds || [])
                .map((_) => envelopeProductsById[_]?.name || '')
                .join(', '),
            },
            envelopeDeliveries: {
              label: '郵便発送処理',
              description: (deliveryHistoryParent?.envelopeProductIds || [])
                .map((_) => envelopeProductsById[_]?.name || '')
                .join(', '),
            },
          }[ref.parent.parent.parent.id] || {};
        const path = `/admin/${ref.parent.parent.path}`;

        return { id, createdAt, label, description, path };
      }),
      ...finishSupportActivities.filter(_ => _.type === 'finishSupportOfInquiry').map((finishSupportActivity) => {
        const { id, createdBy, createdAt, payload } = finishSupportActivity;
        const label = '問合せ完了';
        const description = createdBy.displayName;
        const path = `/admin/inquiries/${payload.inquiryId}`;
        return { id, createdAt, label, description, path };
      }),
      ...finishSupportActivities.filter(_ => _.type === 'finishSupportOfTroubleInquiry').map((finishSupportActivity) => {
        const { id, createdBy, createdAt, payload } = finishSupportActivity;
        const label = '不具合問合せ完了';
        const description = createdBy.displayName;
        const path = `/admin/troubleInquiries/${payload.troubleInquiryId}`;
        return { id, createdAt, label, description, path };
      }),
      ...finishSupportActivities.filter(_ => _.type === 'checkCouponOfOrder').map((activity) => {
        const { id, createdBy, createdAt, payload } = activity;
        const label = '優待確認';
        const description = createdBy.displayName;
        const path = `/admin/orders/${payload.orderId}`;
        return { id, createdAt, label, description, path };
      }),
      ...finishSupportActivities.filter(_ => _.type === 'uncheckCouponOfOrder').map((activity) => {
        const { id, createdBy, createdAt, payload } = activity;
        const label = '優待確認解除';
        const description = createdBy.displayName;
        const path = `/admin/orders/${payload.orderId}`;
        return { id, createdAt, label, description, path };
      }),
      ...[referredEmail != null && {
        ...pick(referredEmail, ['id', 'createdAt']),
        label: '紹介QRコード遷移先へのリダイレクト前のメールアドレス入力',
        description: referredEmailQrUrl?.name,
        path: `/referralRedirect?${qs.stringify({ qrUrlId: referredEmailQrUrl?.id, redirectUrl: referredEmailQrUrl?.redirectUrl, referrerKey: referredEmail?.referrerKey })}`,
      }].filter(_ => _),
    ],
    ({ createdAt }) => createdAt.toDate(),
    'asc'
  );
  const onSubmitEmailForm = async (values) => {
    try { 
      const { data } = await updateUserEmail({ userId, ...values });
      if (data && data.error) {
        console.error(data.error);
        toast.error(data.error);
        return;
      }
      await user?.ref?.update({
        ...values,
        updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
      });
      toggleEmailFormModal(false);
      toast.success('メールアドレスを変更しました');
    } catch(e) {
      console.error(e);
      toast.error('メールアドレスの変更に失敗しました');
    }
  };
  const adminOrdersQueryParams = {
    field: ['createdBy.uid'],
    text: user?.uid || '',
  };
  const adminOrdersPath = `/admin/orders?${qs.stringify(adminOrdersQueryParams)}`;
  const adminTroubleInquiriesQueryParams = {
    field: ['createdBy.uid'],
    text: user?.uid || '',
  };
  const adminTroubleInquiriesPath = `/admin/troubleInquiries?${qs.stringify(adminTroubleInquiriesQueryParams)}`;
  const adminInquiriesQueryParams = {
    field: ['createdBy.uid'],
    text: user?.uid || '',
  };
  const adminInquiriesPath = `/admin/inquiries?${qs.stringify(adminInquiriesQueryParams)}`;
  const adminEntriesQueryParams = {
    field: ['createdBy.uid'],
    text: user?.uid || '',
  };
  const adminEntriesPath = `/admin/entries?${qs.stringify(adminEntriesQueryParams)}`;
  const AdminSurveyAnswersPath = `/admin/users/${user?.uid}/surveyAnswers`;
  const adminAccessLogsPath = `/admin/users/${user?.uid}/accessLogs`
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [userId]);

  const toggleUserStatus = async (nextStatus) => {
    if (!window.confirm(`本当に${nextStatus === 'enable' ? '有効' : '無効'}にしますか？`)) return;
    try {
      await updateUserStatus({ uid: userId, nextStatus });
      toast.success(`${nextStatus === 'enable' ? '有効' : '無効'}にしました`);
    } catch (e) {
      toast.error('失敗しました');
    }
  };

  const onCreateUserTag = async (value) => {
    const ref = await userTagsRef.add({ tenantId: tenant.id, name: value, createdAt: new Date() });
    return ref.id;
  };

  return (
    user != null && tenantUser != null && (
      <div className="admin-user container">
        <div className="p-4 bg-white my-4">
          <div className="d-flex justify-content-center mb-3">
            <h4>
              アカウント詳細
              {!isEmpty(user.mainUserId) && <div className="ml-1 badge badge-warning">サブアカウント</div>}
            </h4>
          </div>
          <div className="d-flex justify-content-end mb-3 gap-2 flex-wrap text-nowrap">
            {!isEmpty(currentUser.trs110User) && (
              <Button tag="a" href={`http://192.168.241.103/login?user=${encodeURI(currentUser.trs110User)}&password=${btoa(currentUser.trs110Password)}&partyAddress=${user.phone}`} target='_blank'>
                TRS110
                <span className="fas fa-external-link-alt ml-1" />
              </Button>
            )}
            {isEmpty(orders) || (
              <Button tag={TenantLink} to={adminOrdersPath} target="_blank">
                注文一覧
              </Button>
            )}
            {isEmpty(eventEntries) || (
              <Button tag={TenantLink} to={adminEntriesPath} target="_blank">
                イベント申し込み一覧
              </Button>
            )}
            {isEmpty(troubleInquiries) || (
              <Button tag={TenantLink} to={adminTroubleInquiriesPath} target="_blank">
                不具合・組立お問合せ一覧
              </Button>
            )}
            {isEmpty(inquiries) || (
              <Button tag={TenantLink} to={adminInquiriesPath} target="_blank">
                お問合せ一覧
              </Button>
            )}
            {isEmpty(surveyAnswers) || (
              <Button tag={TenantLink} to={AdminSurveyAnswersPath} target="_blank">
                アンケート回答一覧
              </Button>
            )}
            {isEmpty(accessLogs) || (
              <Button tag={TenantLink} to={adminAccessLogsPath} target="_blank">
                アクセスログ一覧
              </Button>
            )}
            <Button onClick={toggleEmailFormModal.bind(null, true)}>メールアドレス変更</Button>
            <ModalButton Modal={ModelFormModal} modalProps={{
              title: '問合せの作成',
              fields: {
                description: {
                  label: '内容',
                  type: 'text',
                  validations: {
                    required: _ => !isEmpty(_),
                  },
                },
              },
              onSubmit: async ({ description }) => {
                try {
                  const id = await generateInquiryId();
                  await inquiriesRef.doc(id).set({
                    name: user.displayName,
                    ...omitBy(pick(user, ['nameKana', 'phone', 'email', 'postalCode', 'prefecture', 'city', 'address']), isEmpty),
                    description,
                    tenantId: tenant.id,
                    files: [],
                    createdAt: new Date(),
                    createdBy: user,
                    myAccount: user,
                    status: 'initial',
                    isAdminCreated: true
                  });
                  toast.success('問い合わせを作成しました');
                } catch(e) {
                  console.error(e);
                  toast.error('失敗しました');
                }
              },
            }}>
              問合せの作成
            </ModalButton>
            <EditButton
              label="権限"
              beforeSubmit={_ => ['staff', 'admin'].includes(_.role) ? { ..._, tenantId: user.tenantId || tenant.id } : _}
              itemRef={user.ref}
              FormModal={ModelFormModal}
              formProps={{
                title: '権限編集',
                fields: pick(adminFields({
                  userTags,
                  onCreateUserTag: onCreateUserTag,
                }), ['role']),
              }}
            />
            <EditButton
              itemRef={tenantUser.ref}
              FormModal={ModelFormModal}
              beforeSubmit={async (values) => {
                await user.ref.update({ ...pick(values, [`blocksSms__${tenant.id}`, `blocksMagazineGroupIds__${tenant.id}`]), });
                return omit(values, [`blocksSms__${tenant.id}`, `blocksMagazineGroupIds__${tenant.id}`]);
              }}
              formProps={{
                title: '編集',
                fields: {
                  ...pick(adminFields({
                    userTags,
                    onCreateUserTag: onCreateUserTag,
                  }), ['ngDm', 'note', 'userTagIds']),
                  ...blockSmsFields({ tenants: [tenant], magazineGroupsGroupedByTenantId: groupBy(magazineGroups, 'tenantId') }),
                },
              }}
            />
            {user.disabledAt ? (
              <Button color="warning" onClick={() => toggleUserStatus('enable')}>
                有効にする
              </Button>
            ) : (
              <Button color="danger" onClick={() => toggleUserStatus('disable')}>
                無効にする
              </Button>
            )}
          </div>
          <div>
            <table className='table table-bordered'>
              <tbody className='thead-light'>
                {entries({
                  ...omit(fields({ magazineGroups }), [
                    'password',
                    'currentPassword',
                    'passwordConfirmation',
                    'accept',
                  ]),
                  ...adminFields({ userTags }),
                  // TODO: fieldSettingの型定義する
                }).map(([fieldName, fieldSetting]) => {
                  const { label } = fieldSetting;
                  const displayValue = fieldDisplayValue(tenantUser[fieldName], fieldSetting);
                  return (
                    <tr key={fieldName}>
                      <th style={{ width: 200 }}>{label}</th>
                      <td style={{ whiteSpace: 'pre-line' }}>
                        {Array.isArray(displayValue) ? displayValue.join(', ') : displayValue}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th style={{ width: 200 }}>SMS/メール配信を停止する</th>
                  <td>
                    {user[`blocksSms__${tenant.id}`] ? 'YES' : 'NO'}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: 200 }}>SMS/メール配信停止グループ</th>
                  <td>
                    {(user[`blocksMagazineGroupIds__${tenant.id}`] || []).map(_ => magazineGroupsById[_]?.name).filter(_ => _).join(', ')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {
            ((mainUser != null && !isEmpty(user.mainUserId)) || (pendingMainUser != null && !isEmpty(user.pendingMainUserId))) && (
              <Fragment>
                <hr className='my-5' />
                <div>
                  <div className='d-flex justify-content-center mb-3'>
                    <h4>メインアカウント</h4>
                  </div>
                  <div className='overflow-auto'>
                    <table className='table table-bordered'>
                      <thead className='thead-light ext-nowrap'>
                        <tr>
                          <th style={{ minWidth: 100 }}>名前</th>
                          <th style={{ minWidth: 120 }}>メールアドレス</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          mainUser != null && (
                            <tr>
                              <td>
                                <TenantLink to={`/admin/users/${mainUser.id}`}>{mainUser.displayName}</TenantLink>
                              </td>
                              <td>
                                {mainUser.email}
                              </td>
                            </tr>
                          )
                        }
                        {
                          pendingMainUser != null && (
                            <tr>
                              <td>
                                <TenantLink to={`/admin/users/${pendingMainUser.id}`}>{pendingMainUser.displayName}</TenantLink>
                                <span className="badge badge-warning mx-2">承認待ち</span>
                              </td>
                              <td>
                                {pendingMainUser.email}
                              </td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </Fragment>
            )
          }
          {
            [...subUsers, ...pendingSubUsers].length !== 0 && (
              <Fragment>
                <hr className='my-5' />
                <div>
                  <div className='d-flex justify-content-center mb-3'>
                    <h4>サブアカウント</h4>
                  </div>
                  <div className='overflow-auto'>
                    <table className='table table-bordered'>
                      <thead className='thead-light ext-nowrap'>
                        <tr>
                          <th style={{ minWidth: 100 }}>名前</th>
                          <th style={{ minWidth: 120 }}>メールアドレス</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subUsers.map((subUser) => {
                          const { id, displayName, email, } = subUser;

                          return (
                            <tr key={id}>
                              <td>
                                <TenantLink to={`/admin/users/${id}`}>{displayName}</TenantLink>
                              </td>
                              <td>
                                {email}
                              </td>
                            </tr>
                          );
                        })}
                        {pendingSubUsers.map((subUser) => {
                          const { id, displayName, email, } = subUser;
                          return (
                            <tr key={id}>
                              <td>
                                <TenantLink to={`/admin/users/${id}`}>{displayName}</TenantLink>
                                <span className="badge badge-warning mx-2">承認待ち</span>
                              </td>
                              <td>
                                {email}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Fragment>
            )
          }
          <hr className="my-5" />
          <div>
            <div className="d-flex justify-content-center mb-3">
              <h4>お子様情報</h4>
            </div>
            <div className="overflow-auto">
              <table className="table table-bordered">
                <thead className="thead-light ext-nowrap">
                  <tr>
                    <th style={{ minWidth: 100 }}>名前</th>
                    <th style={{ minWidth: 120 }}>誕生日</th>
                    <th style={{ minWidth: 200 }}>乗り物経験</th>
                  </tr>
                </thead>
                <tbody>
                  {children.map((child, index) => {
                    const { name, birthday, vehicleExperiences } = child;

                    return (
                      <tr key={index}>
                        <td>{name}</td>
                        <td>
                          <div className="d-flex gap-1">
                            <span>{formatDate(birthday.toDate(), 'yyyy/MM/dd')}</span>
                            <span>({ageDisplay(birthday.toDate())})</span>
                          </div>
                        </td>
                        <td>{vehicleExperiences?.join(', ')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <hr className="my-5" />
          <div>
            <div className="d-flex justify-content-center mb-3">
              <h4>アクティビティ</h4>
            </div>
            <div className="overflow-auto">
              <table className="table table-borderless">
                <tbody>
                  {activities.map((activity, index) => {
                    const { createdAt, label, description, path } = activity;

                    return (
                      <tr key={index}>
                        <td style={{ minWidth: 160, maxWidth: 160 }}>
                          {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                        </td>
                        <td style={{ minWidth: 160, maxWidth: 160 }}>
                          <TenantLink to={path} target="_blank">
                            {label}
                          </TenantLink>
                        </td>
                        <td style={{ minWidth: 200 }}>{description}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <hr className="my-5" />
          <div>
            <div className="d-flex justify-content-center mb-3">
              <h4>コメント</h4>
            </div>
            <div className="mt-4 d-flex justify-content-end mb-3">
              <AddInTenantButton
                label="新規コメント"
                itemRef={commentsRef.doc()}
                processValues={(_) => ({
                  ..._,
                  createdBy: pick(firebaseUser, ['uid', 'email', 'displayName']),
                })}
                FormModal={ModelFormModal}
                formProps={{ title: '新規コメント', fields: commentFields }}
              />
            </div>
            <div>
              {comments.length > 0 ? (
                <div>
                  {comments.map(({ id, body, createdAt, createdBy }) => {
                    return (
                      <div key={id} className="card p-3 mb-3">
                        <div className="small text-muted mb-1">
                          {createdBy?.displayName} {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                        </div>
                        <div style={{ whiteSpace: 'pre-line' }}>{body}</div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>コメントはまだありません</div>
              )}
            </div>
          </div>
        </div>
        {showsEmailFormModal && (
          <ModelFormModal
            isOpen
            fields={emailFields}
            title="メールアドレス変更"
            onSubmit={onSubmitEmailForm}
            onClickClose={toggleEmailFormModal.bind(null, false)}
          />
        )}
      </div>
    )
  );
});
