import React, { Fragment, useEffect, useState, useMemo, } from 'react';
import { Label, Button, Form, FormGroup, Input, Tooltip } from 'reactstrap';
import {
  omitBy,
  upperFirst,
  uniqWith,
  isEqual,
  get,
  omit,
  camelCase,
  pick,
  isEmpty,
  mapValues,
  keyBy,
  uniq,
} from 'lodash';
import { set } from 'lodash/fp';
import { useList } from 'react-use';
import { useToggle, useAsync } from 'react-use';
import classnames from 'classnames';
import numeral from 'numeral';
import sanitizeHtml from 'sanitize-html';
import { isMobile } from 'react-device-detect';

import { activateRichTextHtml } from '../../util';
import firebase, { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import Field from '../Field';
import { toHankaku } from '../../shared/util';
import { prefectures } from '../../shared/config';
import {
  isSplitedOrder,
  checkSimilarOrdersDays,
  fields,
  ordererFields,
  deliveryFields,
  couponFields,
  destinationFields,
  contactorFields,
  orderItemFields,
  wishFields,
} from '../../shared/models/order';
import { displayDate } from '../../shared/models/receivingPlan';
import { childFields } from '../../shared/models/user';
import ChildForm from '../forms/ChildForm';
import OrderFlowSection from '../OrderFlowSection';
import ListForm from '../ListForm';
import AppButton from '../AppButton';
import RichTextContent from '../RichTextContent';
import { getAddressByPostalCode } from '../../util';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const couponsRef = db.collection('coupons');
const qrUrlsRef = db.collection('qrUrls');
const agentsRef = db.collection('agents');
const referredEmailsRef = db.collection('referredEmails');
const { keys } = Object;
const { max, min } = Math;
const checkEmailExistence = functions.httpsCallable('checkEmailExistence');
const checkSimilarOrders = functions.httpsCallable('checkSimilarOrders');
const getAgentProductPublicSettings = functions.httpsCallable('getAgentProductPublicSettings');

function OrderItemForm(props) {
  const {
    products,
    productsWithInfoById,
    values,
    shouldStarts = false,
    onChange,
    agentProductPublicSettingsById,
  } = props;
  const fields = set(
    'productId.options',
    orderItemFields({ products }).productId.options.map((_) => ({
      ..._,
      label: (
        <div className='d-flex align-items-center gap-1'>
          <div>
            <img src={_.product.image} style={{ maxWidth: 100, maxHeight: 60 }} />
          </div>
          <div className='d-flex flex-column'>
            <div>{_.product.name}</div>
            <div>
              &yen;{numeral(agentProductPublicSettingsById[_.product.id]?.price ?? _.product.price).format('0,0')}
            </div>
          </div>
        </div>
      ),
    })),
    orderItemFields({ products })
  );
  const statedFields = useFormState(values, fields, values);
  const selectedProduct = products.find((_) => _.id === statedFields.productId.value);
  const normalOrderCount = selectedProduct
    ? min(statedFields.quantity.value, selectedProduct.normalOrderableQuantity || 0)
    : 0;
  const preOrderCount = selectedProduct
    ? max(statedFields.quantity.value - (selectedProduct.normalOrderableQuantity || 0), 0)
    : 0;
  const satisfyingRecivingPlanItem =
    preOrderCount > 0 ? selectedProduct.receivingPlanItems?.find((_) => _.leftQuantity >= preOrderCount) : null;
  useEffect(() => {
    const product = statedFields.productId.options.find(_ => _.value === statedFields.productId.value)?.product
    onChange({
      ...mapValues(statedFields, 'value'),
      normalOrderCount,
      preOrderCount,
      isValid: statedFields.productId.isValid && statedFields.quantity.isValid,
      planDate: satisfyingRecivingPlanItem ? satisfyingRecivingPlanItem.date : null,
      price: agentProductPublicSettingsById[product?.id]?.price ?? product?.price,
    });
  }, [...Object.values(statedFields).map((_) => _.value), productsWithInfoById]);
  useEffect(() => {
    if (shouldStarts) statedFields.startAll();
  }, [shouldStarts]);

  return (
    <div>
      <div style={{ minWidth: 200 }}>
        <Field
          name='productId'
          {...statedFields.productId}
          inputProps={{
            styles: {
              control: (provided) => {
                return { ...provided, height: '80px' };
              },
              valueContainer: (provided) => {
                return { ...provided, height: '80px' };
              },
            },
          }}
        />
      </div>
      {preOrderCount > 0 && statedFields.quantity.isValid && (
        <div className='alert alert-warning'>
          <span>
            {selectedProduct.normalOrderableQuantity > 0
              ? `${statedFields.quantity.value}個のうち${preOrderCount}個は予約注文になります。`
              : `${preOrderCount}個すべて予約注文になります。`}
          </span>
          <span>（{displayDate(satisfyingRecivingPlanItem.date)}入荷予定）</span>
        </div>
      )}
      <div>
        <Field name='quantity' {...statedFields.quantity} />
      </div>
      {get(selectedProduct, 'note') && (
        <div className='alert alert-info' style={{ whiteSpace: 'pre-line' }}>
          {selectedProduct.note}
        </div>
      )}
    </div>
  );
}

const RecommendedProduct = ({ product, pushOrderItem, agentProductPublicSetting }) => {
  const { id, name, description, image, price, subImages } = product;
  const images = [image, ...(subImages || []).map(({ downloadUrl }) => downloadUrl)];
  const [activeImage, setActiveImage] = useState(image);
  const handleClickAddRecommendedProduct = () => {
    pushOrderItem({
      productId: id,
      quantity: 1,
      normalOrderCount: 1,
      preOrderCount: 0,
      planDate: null,
      isValid: true,
    });
  };

  return (
    <div
      className={`border p-4 d-flex flex-column gap-2 text-center justify-content-between ${isMobile && 'flex-fill'}`}
      style={{ width: '260px' }}
    >
      <div className='d-flex justify-content-center'>
        <div className='d-flex flex-column gap-2'>
          <img src={activeImage} style={{ height: 150 }} />
          {images.length > 1 && (
            <div className='d-flex justify-content-center gap-2'>
              {images.map((_, index) => {
                const handleClick = () => {
                  setActiveImage(_);
                };

                return <img key={index} src={_} style={{ height: 30 }} onClick={handleClick} />;
              })}
            </div>
          )}
        </div>
      </div>
      <div className='small font-weight-bold'>{name}</div>
      <RichTextContent className='small mb-0 recommended-product-description' html={agentProductPublicSetting?.description || description || ''} />
      <div className='d-flex justify-content-center gap-3 align-items-center'>
        <div>&yen;{numeral(agentProductPublicSetting?.price ?? price).format('0,0')}</div>
        <div>
          <Button className='text-nowrap' color='secondary' size='sm' onClick={handleClickAddRecommendedProduct}>
            <span className='fas fa-plus mr-1' />
            注文に追加
          </Button>
        </div>
      </div>
    </div>
  );
};

const RecommendedProducts = ({ recommendedProducts, pushOrderItem, agentProductPublicSettingsById }) => {
  return (
    <div className='d-flex flex-wrap gap-2 justify-content-between'>
      {recommendedProducts.map((product) => (
        <RecommendedProduct
          key={product.id}
          {...{ product, pushOrderItem, agentProductPublicSetting: agentProductPublicSettingsById[product.id] }}
        />
      ))}
    </div>
  );
};

export default function NewOrderForm(props) {
  const {
    user,
    referrer,
    qrUrlId,
    products = [],
    productsWithInfoById,
    agentProductPublicSettings,
    values,
    wholesaleAgentShop,
    isWholesale,
    isWish,
    isPartsOrder,
    cartSettings,
    onClickLogin,
    onClickBack,
    hideAddButton = false,
  } = props;
  const { coupon: _showsCouponInput = '0', couponId } = useQueryParams();
  const agentProductPublicSettingsById = keyBy(agentProductPublicSettings, 'id');
  const showsCouponInput = _showsCouponInput === '1';
  const userValues = user != null ? { name: user.displayName, ...user } : {};
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const [destinationType, setDestinationType] = useState(values.destinationType);
  const [deliveryType, setDeliveryType] = useState(values.deliveryType);
  const [contactorType, setContactorType] = useState(values.contactorType);
  const [splitType, setSplitType] = useState(values.splitType);
  const [password, setPassword] = useState('');
  const [extraValidationErrors, setExtraValidationErrors] = useState({});
  const isLoggedIn = values.name != null;
  const [orderItems, { set: setOrderItems, updateAt: updateOrderItemAt, push: pushOrderItem }] = useList(
    values.orderItems
  );
  const [children, { set: setChildren, updateAt: updateChildAt }] = useList(values.children || []);
  const statedFields = useFormState(
    values,
    fields({ isWish, isWholesale, cartSettings, hasPreOrder: orderItems.some((_) => _.preOrderCount > 0) }),
    values
  );
  const referredEmail = useDocumentSubscription(
    statedFields.email.value && referredEmailsRef.doc(statedFields.email.value),
    [statedFields.email.value]
  );
  const { value: agentProductPublicSettingsForReferredEmail = [], loading: isLoadingAgentProductPublicSettings = false } = useAsync(async () => {
    if (referredEmail != null) {
      const { data } = await getAgentProductPublicSettings({ agentId: referredEmail.agentId, });
      return data;
    }
  }, [referredEmail]);
  const agentProductPublicSettingsForReferredEmailById = keyBy(agentProductPublicSettingsForReferredEmail, 'id');
  const agentProductIdsByReferredEmail = products.filter(_ => agentProductPublicSettingsForReferredEmailById[_.id]?.isShownForReferral || (!_.isHidden && !agentProductPublicSettingsForReferredEmailById[_.id]?.isHiddenForReferral)).map(_ => _.id);
  const enablesReferredEmail = orderItems.some(_ => agentProductIdsByReferredEmail.includes(_.productId));
  const conclusiveQrUrlId = qrUrlId || (enablesReferredEmail ? get(referredEmail, 'qrUrlId'): null);
  const qrUrl = useDocumentSubscription(conclusiveQrUrlId && qrUrlsRef.doc(conclusiveQrUrlId), [conclusiveQrUrlId]);
  const agentShop = useDocumentSubscription(
    (referrer && referrer.ref.parent.parent) ||
      (referredEmail && enablesReferredEmail && agentsRef.doc(referredEmail.agentId).collection('agentShops').doc(referredEmail.agentShopId)),
    [referrer, enablesReferredEmail, referredEmail]
  );
  const conclusiveAgentShop = useMemo(_ => wholesaleAgentShop || agentShop, [wholesaleAgentShop, agentShop]);
  const agent = useDocumentSubscription(conclusiveAgentShop?.ref.parent.parent, [conclusiveAgentShop]);
  const { value: couponDoc } = useAsync(async () => {
    if (statedFields.couponId.value == null) return;

    const doc = await couponsRef.doc(statedFields.couponId.value).get();
    return doc;
  }, [statedFields.couponId.value]);
  const couponIds = useMemo(_ => uniq([qrUrl?.couponIds, agent?.showingQrUrls?.find(_ => _.qrUrlId === qrUrl?.id)?.couponIds, couponId].flatMap(_ => _ || [])), [qrUrl, agent, couponId]);
  const otherCoupons = useDocumentsFetch(couponIds?.slice(1).map(_ => couponsRef.doc(_)), [couponIds]);
  const { value: isExistingEmail } = useAsync(async () => {
    if (!statedFields.email.isValid) return;

    const { data } = await checkEmailExistence({ email: statedFields.email.value });
    return data.exists;
  }, [statedFields.email.value]);
  const [showsContactorTooltip, toggleContactorTooltip] = useToggle(false);
  const [showDeliveryTooltip, toggleDeliveryTooltip] = useToggle(false);
  const coupon = get(couponDoc, 'exists') ? { id: couponDoc.id, ...couponDoc.data() } : null;
  const coupons = [coupon, ...otherCoupons].filter(_ => _);
  const isCouponStarted = coupon != null && (coupon.startDate == null || coupon.startDate.toDate() <= new Date());
  const onChangeOrderItems = (items, prevItems) => {
    setOrderItems(items);
  };
  const isMultiPlanDates =
    uniqWith(
      orderItems.map((_) => _.planDate).filter((_) => _),
      isEqual
    ).length > 1;
  const productsById = keyBy(products, 'id');
  const recommendedProductIds = uniq(
    orderItems.map(({ productId }) => productsById[productId]?.recommendedProductIds || []).flat()
  );
  const recommendedProducts = recommendedProductIds
    .map((id) => productsById[id])
    .filter((_) => _)
    .filter(({ normalOrderableQuantity }) => normalOrderableQuantity > 0)
    .filter(({ id }) => orderItems.every(({ productId }) => productId !== id))
    .slice(0, 2);
  let fieldsToValidate = statedFields;
  if (destinationType === 'same') {
    fieldsToValidate = omit(fieldsToValidate, keys(destinationFields()));
  }
  if (isPartsOrder || ['orderer', 'destination'].includes(contactorType)) {
    fieldsToValidate = omit(fieldsToValidate, keys(contactorFields()));
  }
  if (!showsCouponInput && couponDoc == null) {
    fieldsToValidate = omit(fieldsToValidate, keys(couponFields()));
  }
  const validationErrorMessages = uniq([
    ...Object.values(fieldsToValidate)
      .filter((_) => !_.isValid)
      .map(({ label, validationErrors }) => {
        return `${label}にエラーがあります`;
      }),
    orderItems.some((_) => !_.isValid) && '商品選択にエラーがあります',
    isEmpty(get(user, 'children', [])) && children.some((_) => !_.isValid) && 'お子様情報にエラーがあります',
    user == null && password.length < 6 && 'パスワードにエラーがあります',
    ...Object.keys(extraValidationErrors).map((_) => `${fields()[_].label}にエラーがあります`),
  ].filter((_) => _));
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    statedFields.startAll();
    if (validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    toggleSubmitting(true);
    const ordererValues = mapValues(pick(statedFields, keys(ordererFields())), 'value');
    const destinationValues = {
      same: keys(destinationFields()).reduce((x, y) => {
        return {
          ...x,
          [y]: ordererValues[camelCase(y.replace(/^destination/, ''))],
        };
      }, {}),
      other: mapValues(pick(statedFields, keys(destinationFields())), 'value'),
      shop: mapValues(pick(statedFields, keys(destinationFields())), 'value'),
    }[destinationType];
    const contactorValues =
      !isPartsOrder &&
      {
        orderer: keys(contactorFields()).reduce((x, y) => {
          return {
            ...x,
            [y]: ordererValues[camelCase(y.replace(/^contactor/, ''))],
          };
        }, {}),
        destination: keys(contactorFields()).reduce((x, y) => {
          return {
            ...x,
            [y]: destinationValues[camelCase(y.replace(/^contactor/, 'destination'))],
          };
        }, {}),
        other: mapValues(pick(statedFields, keys(contactorFields())), 'value'),
      }[contactorType];
    let exists = false;
    // NOTE: 類似注文確認が失敗しても動くように
    try {
      const { data } = await checkSimilarOrders({
        ...pick(ordererValues, ['name', 'email', 'phone']),
        ...pick(destinationValues, ['destinationName', 'destinationPhone']),
        productIds: orderItems.map((_) => _.productId),
      });
      exists = data.exists;
    } catch (e) {
      console.error(e);
    }
    if (
      exists &&
      !window.confirm(`${checkSimilarOrdersDays}日以内に同じ商品の注文履歴があります。このまま注文を続けますか？`)
    )
      return toggleSubmitting(false);

    await props.onSubmit({
      orderItems,
      children,
      ...ordererValues,
      ...destinationValues,
      ...contactorValues,
      ...mapValues(
        pick(statedFields, keys({ ...couponFields(), ...deliveryFields({ isWish, isWholesale, cartSettings, }), ...wishFields({ isWish, }), })),
        'value'
      ),
      destinationType,
      contactorType,
      splitType,
      password,
      referrerKey: get(referrer, 'key') || (enablesReferredEmail ? get(referredEmail, 'referrerKey') : null) || null,
      qrUrlId: conclusiveQrUrlId || null,
      ...(isCouponStarted && get(coupon, 'status') === 'initial' && { coupon }),
      otherCoupons: otherCoupons.filter(_ => (_.startDate == null || _.startDate.toDate() <= new Date()) && _.status === 'initial'),
    });
  };
  const handleChangePostalCode = async ({ fieldNames }, { target: { name, value } }) => {
    if (value?.length !== 7) return;

    try {
      const { prefecture, city, address1 } = await getAddressByPostalCode(value);
      setExtraValidationErrors(omit(extraValidationErrors, [name]));
      statedFields.setValues({
        [name]: value,
        [fieldNames.prefecture]: prefecture,
        [fieldNames.city]: city + address1,
      });
    } catch (e) {
      console.error(e);
      setExtraValidationErrors({ ...extraValidationErrors, [name]: ['existingPostalCode'] });
    }
  };
  const handleClickBack = () => {
    onClickBack?.({ orderItems });
  };

  useEffect(() => {
    if (values.children != null) {
      setChildren(values.children);
    }
  }, [values.children]);
  useEffect(() => {
    if (destinationType === 'shop') {
      statedFields.setValues(
        ['name', 'nameKana', 'phone', 'postalCode', 'prefecture', 'city', 'address'].reduce((x, k) => {
          return {
            ...x,
            [`destination${upperFirst(k)}`]: (wholesaleAgentShop || agentShop)[k],
          };
        }, {})
      );
    }
    if (destinationType === 'other') {
      statedFields.setValues(
        ['name', 'nameKana', 'phone', 'postalCode', 'prefecture', 'city', 'address'].reduce((x, k) => {
          return {
            ...x,
            [`destination${upperFirst(k)}`]: '',
          };
        }, {})
      );
    }
  }, [destinationType]);
  useEffect(() => {
    if (user != null) {
      statedFields.setValues({
        name: user.displayName,
        ...omitBy(
          pick(user, [
            'nameKana',
            'phone',
            'email',
            'postalCode',
            'prefecture',
            'city',
            'address',
            'route',
            'children',
          ]),
          isEmpty
        ),
      });
    }
  }, [user]);
  useEffect(() => {
    if (!isEmpty(couponIds)) {
      statedFields.couponId.setValue(couponIds[0]);
    }
  }, [couponIds]);

  return (
    <Form onSubmit={onSubmit}>
      <OrderFlowSection activeIndex={1} />
      <section className='container mt-5'>
        <div className='row'>
          <div className='col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
            <h4 className='h5 text-center font-weight-bold'>
              {isWish ? 'おねだり注文' : 'ご注文'}
            </h4>
            {user == null && (
              <div className='mt-4 card p-3'>
                <div className='mb-2'>すでにアカウントをお持ちの方はログインしてください</div>
                <Button color='primary' size='lg' block onClick={onClickLogin}>
                  ログインする
                </Button>
              </div>
            )}
            <div className='mt-5'>
              <div className='mb-3'>
                <label>商品</label>
                <ListForm
                  items={orderItems}
                  renderItem={(item, itemIndex) => {
                    return (
                      <div className='card p-3'>
                        <OrderItemForm
                          orderItems={orderItems}
                          values={item}
                          onChange={(_) => updateOrderItemAt(itemIndex, { ...item, ..._ })}
                          products={products}
                          productsWithInfoById={productsWithInfoById}
                          shouldStarts={hasSubmitted}
                          agentProductPublicSettingsById={agentProductPublicSettingsById}
                        />
                      </div>
                    );
                  }}
                  onChange={onChangeOrderItems}
                  minItems={1}
                  initialItem={{ quantity: 1 }}
                  addButtonLabel='商品を追加する'
                  hideAddButton={hideAddButton}
                />
                {onClickBack && (
                  <AppButton color='secondary' className='cancel' onClick={handleClickBack}>
                    <span className='fas mr-1 fa-arrow-left' />
                    買い物を続ける
                  </AppButton>
                )}
                {recommendedProducts.length > 0 && (
                  <div className='mt-4'>
                    <h5 className='mb-2'>おすすめ</h5>
                    <RecommendedProducts {...{ recommendedProducts, pushOrderItem, agentProductPublicSettingsById }} />
                  </div>
                )}
                {isMultiPlanDates && (
                  <div className='mt-3 card border-warning p-3'>
                    ２種類以上予約注文する場合、それぞれ分けて注文をお薦めします。
                    <br />
                    同時注文の場合、全ての商品が入荷してから発送となります。
                  </div>
                )}
                {isSplitedOrder(orderItems) && (
                  <div className='mt-3 card border-warning p-3'>
                    <FormGroup tag='fieldset'>
                      <FormGroup check>
                        <Label check>
                          <Input checked={splitType === 'one'} type='radio' onChange={(_) => setSplitType('one')} />{' '}
                          同時発送希望（送料は最低限になります）
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input checked={splitType === 'split'} type='radio' onChange={(_) => setSplitType('split')} />{' '}
                          在庫のあるものから順次発送（それぞれに送料がかかります）
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </div>
                )}
              </div>
              {(showsCouponInput || couponDoc != null) && (
                <div className='mt-5'>
                  <h5>優待</h5>
                  {showsCouponInput &&
                    keys(couponFields()).map((fieldName) => {
                      const fieldSetting = statedFields[fieldName];
                      return <Field key={fieldName} name={fieldName} documentName='order' {...fieldSetting} />;
                    })}
                  <div className="d-flex flex-column gap-1">
                    {
                      coupons.map((coupon, i) => {
                        const isCouponStarted = coupon != null && (coupon.startDate == null || coupon.startDate.toDate() <= new Date());
                        return (
                          <div>
                            {i === 0 && !couponDoc.exists ? (
                              <div className='text-danger'>優待コードが見つかりません</div>
                            ) : (
                              <div className='border border-info rounded p-2'>
                                <h6 className="font-weight-bold">優待内容</h6>
                                <RichTextContent className="mt-2" html={coupon.description} />
                                {!isCouponStarted ? (
                                  <div className='mt-2 text-danger'>この優待コードはまだ開始していません</div>
                                ) : (
                                  {
                                    used: (
                                      <div className='mt-2 text-danger'>この優待コードは利用済みのため割引できません</div>
                                    ),
                                    disabled: (
                                      <div className='mt-2 text-danger' style={{ whiteSpace: 'pre-line' }}>
                                        {coupon.disabilityMessage || 'この優待コードは現在利用できません'}
                                      </div>
                                    ),
                                  }[coupon.status] || null
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              )}
              {isLoggedIn && (
                <div>
                  <div className='alert alert-info'>
                    プロフィールは
                    <TenantLink to='/mypage/profile' target='_blank'>
                      こちら
                      <span className='fas fa-external-link-alt ml-1' />
                    </TenantLink>
                    で変更できます
                  </div>
                </div>
              )}
              <div className='mt-5'>
                <h5>注文者情報</h5>
                {keys(ordererFields()).map((fieldName) => {
                  const fieldSetting = statedFields[fieldName];
                  if (fieldName === 'postalCode') {
                    return (
                      <Field
                        key={fieldName}
                        name={fieldName}
                        documentName='order'
                        {...fieldSetting}
                        {...(extraValidationErrors[fieldName] && {
                          validationErrors: [...fieldSetting.validationErrors, ...extraValidationErrors[fieldName]],
                        })}
                        readOnly={!isEmpty(userValues[fieldName]) ? (_) => true : fieldSetting.readOnly}
                        onChange={handleChangePostalCode.bind(null, {
                          fieldNames: { prefecture: 'prefecture', city: 'city' },
                        })}
                      />
                    );
                  } else {
                    return (
                      <Field
                        key={fieldName}
                        name={fieldName}
                        documentName='order'
                        {...fieldSetting}
                        {...['city', 'address'].includes(fieldName) && { inputProps: { onBlur: _ => fieldSetting.setValue(toHankaku(fieldSetting.value)), } }}
                        readOnly={!isEmpty(userValues[fieldName]) ? (_) => true : fieldSetting.readOnly}
                      />
                    );
                  }
                })}
              </div>
              <div className='mt-5'>
                <h5>お届け先情報</h5>
                <div>
                  <FormGroup tag='fieldset'>
                    <FormGroup check>
                      <Label check>
                        <Input
                          checked={destinationType === 'same'}
                          type='radio'
                          onChange={(_) => setDestinationType('same')}
                        />{' '}
                        注文者の住所にお届けする
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          checked={destinationType === 'other'}
                          type='radio'
                          onChange={(_) => setDestinationType('other')}
                        />{' '}
                        新しい届け先を入力する
                      </Label>
                    </FormGroup>
                    {conclusiveAgentShop != null && !conclusiveAgentShop.hidesShopDestination && (
                      <FormGroup check>
                        <Label check>
                          <Input
                            checked={destinationType === 'shop'}
                            type='radio'
                            onChange={(_) => {
                              setDestinationType('shop');
                              setContactorType('orderer');
                            }}
                          />{' '}
                          店舗に送る
                        </Label>
                      </FormGroup>
                    )}
                  </FormGroup>
                </div>
                {['other', 'shop'].includes(destinationType) &&
                  keys(destinationFields()).map((fieldName) => {
                    const fieldSetting = statedFields[fieldName];
                    if (fieldName === 'destinationPostalCode') {
                      return (
                        <Field
                          key={fieldName}
                          name={fieldName}
                          documentName='order'
                          {...fieldSetting}
                          {...(extraValidationErrors[fieldName] && {
                            validationErrors: [...fieldSetting.validationErrors, ...extraValidationErrors[fieldName]],
                          })}
                          onChange={handleChangePostalCode.bind(null, {
                            fieldNames: { prefecture: 'destinationPrefecture', city: 'destinationCity' },
                          })}
                        />
                      );
                    } else {
                      return (
                        <Field
                          key={fieldName}
                          name={fieldName}
                          documentName='order'
                          {...fieldSetting}
                          {...['destinationCity', 'destinationAddress'].includes(fieldName) && { inputProps: { onBlur: _ => fieldSetting.setValue(toHankaku(fieldSetting.value)), } }}
                        />
                      );
                    }
                  })}
              </div>
              {isEmpty(get(user, 'children', [])) && (
                <div className='mt-5'>
                  <h5>お子様情報</h5>
                  <div className='mb-2 card border-info text-info p-3 text-center font-weight-bold'>
                    お子様の成長に合わせた
                    <br className='d-sm-none' />
                    サポートが受けられます。
                  </div>
                  <div>
                    <ListForm
                      addButtonLabel='お子様を追加する'
                      items={children}
                      renderItem={(item, itemIndex) => {
                        return (
                          <div className='card p-3'>
                            <ChildForm
                              fields={childFields()}
                              index={itemIndex}
                              values={item}
                              onChange={(_) => updateChildAt(itemIndex, { ...item, ..._ })}
                              shouldStarts={hasSubmitted}
                            />
                          </div>
                        );
                      }}
                      onChange={setChildren}
                      minItems={0}
                    />
                  </div>
                </div>
              )}
              {!isPartsOrder && (
                <div className='mt-5'>
                  <div className="d-flex align-items-baseline">
                    <h5>利用者情報</h5>
                    <span id="support-information" className="fas fa-info-circle text-muted ml-1" />
                    <Tooltip placement="top" isOpen={showsContactorTooltip} target="support-information" toggle={toggleContactorTooltip} style={{ maxWidth: '340px' }}>
                      実際に商品をお使いになる方<br />
                      保証やサービスを受けられる方はどなたですか？
                    </Tooltip>
                  </div>
                  <div>
                    <FormGroup tag='fieldset'>
                      <FormGroup check>
                        <Label check>
                          <Input
                            checked={contactorType === 'orderer'}
                            type='radio'
                            onChange={(_) => setContactorType('orderer')}
                          />{' '}
                          注文者と同じ
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            checked={contactorType === 'destination'}
                            type='radio'
                            onChange={(_) => setContactorType('destination')}
                          />{' '}
                          届け先と同じ
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            checked={contactorType === 'other'}
                            type='radio'
                            onChange={(_) => setContactorType('other')}
                          />{' '}
                          その他（贈り物の場合）
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </div>
                  {contactorType === 'other' &&
                    keys(contactorFields()).map((fieldName) => {
                      const fieldSetting = statedFields[fieldName];
                      if (fieldName === 'contactorPostalCode') {
                        return (
                          <Field
                            key={fieldName}
                            name={fieldName}
                            documentName='order'
                            {...fieldSetting}
                            {...(extraValidationErrors[fieldName] && {
                              validationErrors: [...fieldSetting.validationErrors, ...extraValidationErrors[fieldName]],
                            })}
                            {...['contactorCity', 'contactorAddress'].includes(fieldName) && { inputProps: { onBlur: _ => fieldSetting.setValue(toHankaku(fieldSetting.value)), } }}
                            onChange={handleChangePostalCode.bind(null, {
                              fieldNames: { prefecture: 'contactorPrefecture', city: 'contactorCity' },
                            })}
                          />
                        );
                      } else {
                        return (
                          <Field
                            key={fieldName}
                            name={fieldName}
                            documentName='order'
                            {...fieldSetting}
                            {...['contactorCity', 'contactorAddress'].includes(fieldName) && { inputProps: { onBlur: _ => fieldSetting.setValue(toHankaku(fieldSetting.value)), } }}
                          />
                        );
                      }
                    })}
                </div>
              )}
              <div className='mt-5'>
                <div className="d-flex align-items-baseline">
                  <h5>お届け日時</h5>
                  {
                    cartSettings.deliveryDateTimeDescription && (
                      <Fragment>
                        <span id="delivery-date-time-description" className="fas fa-info-circle text-muted ml-1" />
                        <Tooltip placement="top" isOpen={showDeliveryTooltip} target="delivery-date-time-description" toggle={toggleDeliveryTooltip} style={{ maxWidth: '340px' }}>
                          <RichTextContent html={cartSettings.deliveryDateTimeDescription} />
                        </Tooltip>
                      </Fragment>
                    )
                  }
                </div>
                <div>
                  <FormGroup tag='fieldset'>
                    <FormGroup check>
                      <Label check>
                        <Input
                          checked={deliveryType === 'fastest'}
                          type='radio'
                          onChange={(_) => setDeliveryType('fastest')}
                        />{' '}
                        最短で発送希望（土日祝を除く1～3営業日で出荷）
                      </Label>
                    </FormGroup>
                    {
                      !isWish && (
                        <FormGroup check>
                          <Label check>
                            <Input
                              checked={deliveryType === 'specified'}
                              type='radio'
                              onChange={(_) => setDeliveryType('specified')}
                            />{' '}
                            配送日を指定する
                          </Label>
                        </FormGroup>
                      )
                    }
                  </FormGroup>
                </div>
                {keys(
                  deliveryFields({
                    isWish,
                    isWholesale,
                    cartSettings,
                    hasPreOrder: orderItems.some((_) => _.preOrderCount > 0),
                  })
                ).map((fieldName) => {
                  const fieldSetting = statedFields[fieldName];
                  if (fieldName === 'deliveryDate' && deliveryType === 'fastest') return null;
                  return <Field key={fieldName} name={fieldName} documentName='order' {...fieldSetting} />;
                })}
              </div>
              {
                isWish && (
                  <div className='mt-5'>
                    <h5>おねだりメッセージ</h5>
                    <div>
                      {keys(wishFields({ isWish })).map((fieldName) => {
                        const fieldSetting = statedFields[fieldName];
                        return (
                          <Field
                            key={fieldName}
                            name={fieldName}
                            documentName='order'
                            {...fieldSetting}
                          />
                        );
                      })}
                    </div>
                  </div>
                )
              }
            </div>
            {user == null &&
              (isExistingEmail ? (
                <div className='mt-4 card p-3'>
                  <div className='alert alert-warning mb-3'>
                    お客様のメールアドレスはすでにアカウント登録されています。
                    <br />
                    ログインしてください。
                  </div>
                  <Button color='primary' size='lg' block onClick={onClickLogin}>
                    ログインする
                  </Button>
                </div>
              ) : (
                <div className='mt-5'>
                  <h5>アカウント登録</h5>
                  <div className='alert alert-info'>
                    30分で乗れる自転車教室の予約やへんしんバイクを購入後の乗り方サポート、メンテナンスサポートを受けられます。
                  </div>
                  <div>
                    <FormGroup>
                      <Label>
                        パスワード
                        <span className='text-danger small'>【必須】</span>
                      </Label>
                      <Input
                        type='password'
                        value={password}
                        onChange={(_) => setPassword(_.target.value)}
                        minLength={6}
                      />
                      <small className='form-text text-muted'>6文字以上で指定してください</small>
                    </FormGroup>
                  </div>
                </div>
              ))}
            {!(user == null && isExistingEmail) && (
              <div className='d-flex mt-5'>
                <AppButton
                  size='lg'
                  color='primary'
                  className='save flex-fill ml-2'
                  type='submit'
                  onClick={onSubmit}
                  disabled={isSubmitting}
                >
                  <span
                    className={classnames('fas mr-1', {
                      'fa-arrow-right': !isSubmitting,
                      'fa-spin fa-spinner': isSubmitting,
                    })}
                  />
                  確認画面
                </AppButton>
              </div>
            )}
          </div>
        </div>
      </section>
    </Form>
  );
}
