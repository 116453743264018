const { isEmpty, } = require('lodash');
const dedent = require('dedent');

module.exports = {
  fields: ({ type }) => {
    return {
      subject: {
        label: 'タイトル',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      body: {
        label: '内容',
        type,
        rows: 10,
        disablesTextTemplates: true,
        hint: dedent`
          ログインユーザー名: {{LOGIN_USER_NAME}}
        `,
        validations: {
          required: v => !isEmpty(v),
        },
      },
    };
  },
};
