import React, { useState } from 'react';
import { sumBy, uniq } from 'lodash';
import { format as formatDate } from 'date-fns';
import { Input } from 'reactstrap';
import numeral from 'numeral';

import firebase from '../firebase';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useTenant from './hooks/useTenant';
import SettingText from './SettingText';

const db = firebase.firestore();

export default function PurchaseOrderContent (props) {
  const { purchaseOrder, products, productsById, } = props;
  const bodyProducts = products.filter(_ => _.isBody);
  const displayProducts = bodyProducts.filter(_ => !_.isHidden);
  const displayProductCodePrefixes = uniq(displayProducts.map(_ => _.id.split('-')[0]));
  console.log(purchaseOrder);
  const tenant = useTenant();

  return (
    <div className="purchase-order" style={{ width: 1200, }}>
      <h5 className="font-weight-bold">
        Purchase Order
      </h5>
      <div className="d-flex justify-content-between">
        <div>
          <table className="table border-2 table-bordered table-sm">
            <tbody>
              <tr>
                <th>
                  PO Number
                </th>
                <th>
                  PO Date
                </th>
              </tr>
              <tr>
                <td>
                  {purchaseOrder.poNumber}
                </td>
                <td>
                  {formatDate(new Date(), 'MMM dd, yyyy')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <SettingText name="poTopRight" />
        </div>
      </div>
      <div className="mt-3">
        <table className="table border-2 table-bordered table-sm">
          <thead className="thead-dark text-center">
            <tr>
              <th>
                Item No
              </th>
              <th>
                Description
              </th>
              <th>
                Quantity
              </th>
              <th>
                Price
              </th>
              <th>
                Item Total Price
              </th>
            </tr>
          </thead>
          <tbody>
            {
              Array(20).fill().map((_, i) => {
                const item = purchaseOrder.items[i];
                const { productId, quantity } = item ?? {};
                const product = productsById[productId];
                return (
                  <tr key={i}>
                    <td>
                      {productId}
                      &nbsp;
                    </td>
                    <td>
                      {product?.enName}
                    </td>
                    <td className="text-right">
                      {product && numeral(quantity).format()}
                    </td>
                    <td className="text-right">
                      {product && numeral(product?.usdPrice).format('$0,0.00')}
                    </td>
                    <td className="text-right">
                      {product && numeral(product?.usdPrice * quantity).format('$0,0.00')}
                    </td>
                  </tr>
                );
              })
            }
            <tr>
              <th colSpan={2} className="text-right">
                Total
              </th>
              <th className="text-right">
                {numeral(sumBy(purchaseOrder.items, 'quantity')).format()}
              </th>
              <th>
              </th>
              <th className="text-right">
                {numeral(sumBy(purchaseOrder.items, _ => productsById[_.productId]?.usdPrice * _.quantity)).format('$0,0.00')}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
