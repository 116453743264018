import React from 'react';

import TenantPage from './TenantPage';
import UserPage from './UserPage';

export default function TenantUserPageHOC(WrappedComponent) {
  function TenantUserPage(props) {
    const { setLang, } = props;
    return (
      <div className="tenant-user-page h-100">
        <WrappedComponent {...props} />
      </div>
    );
  }
  ['preview', 'initiallyOpenLogin'].map(_ => TenantUserPage[_] = WrappedComponent[_]);

  return TenantPage(UserPage(TenantUserPage, { disablesPageHoc: true }));
}
