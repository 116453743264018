import React from 'react';
import { format as formatDate } from 'date-fns';
import { omit, sortBy, pick, orderBy, upperFirst, keyBy, get, camelCase } from 'lodash';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import dedent from 'dedent';

import firebase, { functions } from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import TenantLink from '../TenantLink';
import {
  adminFields,
  commentFields,
  activityTypes,
  supportContentsFields,
  emailFields,
  replyEmailBody,
  itemFields,
  shipmentFields,
  shipmentMailBody,
} from '../../shared/models/surveyAnswer';
import { defaultGroups } from '../../shared/models/surveyGroup';
import { auditData } from '../../shared/models/user';
import useCreateActivity from '../hooks/useCreateActivity';
import { InquiryUpdatePicButton } from '../InquiryUpdatePicButton';
import AddInTenantButton from '../AddInTenantButton';
import ModelFormModal from '../modals/ModelFormModal';
import Activity from '../Activity';
import EditButton from '../EditButton';
import { supportMeans } from '../../shared/config';
import ModalButton from '../ModalButton';
import { canUpdateSurveyAnswer } from '../../shared/abilities';
import { InquiryShipmentButton } from '../InquiryShipmentButton';
import { paymentMethod, destinationFields } from '../../shared/models/order';
import InquiryItemsFormModal from '../modals/InquiryItemsFormModal';
import SurveyAnswerEditButton from '../SurveyAnswerEditButton';

const db = firebase.firestore();
const { keys } = Object;
const thWidth = 350;
const surveyAnswersRef = db.collection('surveyAnswers');
const activitiesRef = db.collection('activities');
const surveysRef = db.collection('surveys');
const productsRef = db.collection('products');
const ordersRef = db.collection('orders');
const storageRef = firebase.storage().ref();
const sendSurveyAnswerSupportEmail = functions.httpsCallable('sendSurveyAnswerSupportEmail');
const surveyAnswerShipment = functions.httpsCallable('surveyAnswerShipment');

export default AdminPage(function AdminSurveyAnswer(props) {
  const {
    user,
    match: {
      params: { surveyAnswerId },
    },
  } = props;
  const createActivity = useCreateActivity();
  const surveyAnswer = useDocumentSubscription(surveyAnswersRef.doc(surveyAnswerId), [surveyAnswerId]);
  const survey = useDocumentSubscription(surveyAnswer && db.collection('surveys').doc(surveyAnswer.surveyId), [
    surveyAnswer,
  ]);
  const questions = sortBy(useCollectionSubscriptionInTenant(db.collection('questions')), (_) => _.createdAt.toDate());
  const relatedQuestions = (survey?.questionRows?.map((_) => _.questionId) || []).map(questionId => questions.find(_ => _.id === questionId)).filter(_ => _);
  const _surveyGroups = useCollectionSubscriptionInTenant(db.collection('surveyGroups'));
  const surveyGroups = [...defaultGroups, ..._surveyGroups];
  const surveys = useCollectionSubscriptionInTenant(surveysRef);

  const commentsRef = surveyAnswersRef.doc(surveyAnswerId).collection('surveyAnswerComments');
  const comments = useCollectionSubscriptionInTenant(commentsRef.orderBy('createdAt', 'desc'), [surveyAnswerId]);
  const activities = useCollectionSubscriptionInTenant(
    activitiesRef.where('payload.surveyAnswer.id', '==', surveyAnswerId),
    [surveyAnswerId]
  );
  const products = useCollectionSubscriptionInTenant(productsRef.where('isOption', '==', true).orderBy('createdAt'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts.map((product) => ({
    ...product,
    ...(product.isHidden && { label: `[非表示] ${product.name}` }),
  }));
  const productsById = keyBy(products, 'id');
  const shippable =
    surveyAnswer &&
    !surveyAnswer.shipmentRequested &&
    surveyAnswer.items &&
    ['name', 'nameKana', 'phone', 'postalCode', 'prefecture', 'city', 'address', 'email'].every(_ => surveyAnswer['destination' + upperFirst(_)]) &&
    surveyAnswer.items.every((_) => _.quantity <= get(productsById, [_.productId, 'normalInventory'], 0));
  const shipmentOrderId = surveyAnswer?.orderId;
  const shipmentOrder = useDocumentSubscription(shipmentOrderId && ordersRef.doc(shipmentOrderId), [shipmentOrderId]);

  const onClickStart = async () => {
    if (!window.confirm('本当に対応開始しますか？')) return;

    await surveyAnswer.ref.update({ status: 'supporting', respondedBy: user });
    await createActivity('startSupportOfSurveyAnswer', user, { surveyAnswer, uid: surveyAnswer.createdBy.uid });
  };
  const onClickUnfinish = async () => {
    if (!window.confirm('本当に対応中に戻しますか？')) return;

    await surveyAnswer.ref.update({ status: 'supporting' });
    await createActivity('unfinishSupportOfSurveyAnswer', user, { surveyAnswer, uid: surveyAnswer.createdBy.uid });
  };
  const onSubmitUpdatePic = async () => {
    await createActivity('updatePicOfSurveyAnswer', user, { surveyAnswer, uid: surveyAnswer.createdBy.uid });
  };
  const validateOnDone = async () => {
    if (!window.confirm('本当に対応完了しますか？')) return false;
    return true;
  };
  const onDoneFinished = async (values) => {
    const note = `${values.supportSummary} (${values.supportMeans.map((_) => supportMeans[_]).join(',')})`;
    await surveyAnswer.ref.update({ status: 'supported' });
    await createActivity('finishSupportOfSurveyAnswer', user, { surveyAnswer, note, uid: surveyAnswer.createdBy.uid });
  };
  const onEdited = async ({ items }) => {
    await createActivity('editSurveyAnswer', user, { surveyAnswer, uid: surveyAnswer.createdBy.uid });
  };
  const onShipmentFinished = async () => {
    await createActivity('createOrderOfSurveyAnswer', user, { surveyAnswer, uid: surveyAnswer.createdBy.uid });
  };
  const onEditedDestination = async () => {
    await createActivity('editDestinationOfSurveyAnswer', user, { surveyAnswer, uid: surveyAnswer.createdBy.uid });
  };

  const onSubmitEmail = async (values, { onClickClose }) => {
    try {
      const attachedFiles = await Promise.all(
        Array.from(values.files || []).map(async (file) => {
          const storagePath = `surveyAnswers/${surveyAnswerId}/attachedFiles/${new Date().toISOString()}/${file.name}`;
          const fileRef = storageRef.child(storagePath);
          await fileRef.put(file, { contentType: file.type });
          return {
            ...pick(file, ['name', 'type']),
            storagePath,
            url: await fileRef.getDownloadURL(),
          };
        })
      );
      await sendSurveyAnswerSupportEmail({ surveyAnswerId, values, });
      await surveyAnswer.ref.update({
        messages: firebase.firestore.FieldValue.arrayUnion({ ...omit(values, 'files'), attachedFiles, sentAt: new Date(), sentBy: pick(user, ['email', 'uid', 'displayName']), }),
      });
      const body = replyEmailBody(surveyAnswer, values.body, values.reinquiryContact, window.location.origin);
      await createActivity('sendSurveyAnswerSupportEmail', user, {
        surveyAnswer: { id: surveyAnswerId },
        uid: surveyAnswer.createdBy.uid,
        body,
        messageBody: values.body,
        attachedFiles,
      });
      toast.success('送信しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const mailConfirmBody = ({ values }) => {
    const body = replyEmailBody(surveyAnswer, values.body, values.reinquiryContact, window.location.origin);
    return (
      <div>
        <div>以下のようなメールを送ります。よろしいですか？</div>
        <div className="card p-3 mt-3" style={{ whiteSpace: 'pre-line' }}>
          {body}
        </div>
        <div className="mt-4">
          <h5>メッセージ内容</h5>
          <div className="card p-3" style={{ whiteSpace: 'pre-line', }}>
            {values.body}
          </div>
        </div>
      </div>
    );
  };

  return (
    surveyAnswer != null && (
      <div className="admin-survey-answer container-fluid">
        <div className="p-4 bg-white my-4">
          <div className="d-flex justify-content-center mb-3">
            <h4>アンケートページ回答詳細</h4>
          </div>
          <div className="d-flex flex-wrap text-nowrap justify-content-end mb-3 gap-2">
            {surveyAnswer.status !== 'supported' && (
              <InquiryUpdatePicButton inquiry={surveyAnswer} onSubmit={onSubmitUpdatePic} />
            )}
            {survey?.isAnswerEditable && (
              <SurveyAnswerEditButton {...{ survey, questions, activities, surveyAnswer, user }} />
            )}
            <ModalButton
              Modal={ModelFormModal}
              modalProps={{
                title: 'メール送信',
                fields: emailFields(),
                submitLabel: '送信する',
                onSubmit: onSubmitEmail,
                confirms: true,
                confirmBody: mailConfirmBody,
                values: {
                  reinquiryContact: survey?.reinquiryContact
                }
              }}
            >
              <span className="fas fa-paper-plane mr-1" /> メール送信
            </ModalButton>
            {['supporting', 'awaiting'].includes(surveyAnswer.status) && (
              <>
                <EditButton
                  label="発送先変更"
                  icon={null}
                  itemRef={surveyAnswer.ref}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: '発送先を変更する',
                    fields: destinationFields,
                    submitLabel: '変更する',
                    values: keys(destinationFields()).reduce(
                      (x, y) => ({
                        ...x,
                        [y]: surveyAnswer[y] || user[camelCase(y.replace(/^destination/, ''))] || null,
                      }),
                      {}
                    ),
                  }}
                  disabled={!canUpdateSurveyAnswer(user)}
                  onFinish={onEditedDestination}
                />
                <EditButton
                  itemRef={surveyAnswer.ref}
                  FormModal={InquiryItemsFormModal}
                  disabled={!canUpdateSurveyAnswer(user)}
                  formProps={{ products: selectableProducts, itemFields }}
                  onFinish={onEdited}
                />
                <InquiryShipmentButton
                  inquiry={surveyAnswer}
                  user={user}
                  disabled={!shippable}
                  products={products}
                  shipmentFields={shipmentFields}
                  shipmentMailBody={shipmentMailBody}
                  onFinish={onShipmentFinished}
                  inquiryShipment={surveyAnswerShipment}
                  shipmentOrder={shipmentOrder}
                />
                <EditButton
                  label="対応完了"
                  icon={null}
                  color="primary"
                  itemRef={surveyAnswer.ref}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: 'サポート内容を登録する',
                    fields: supportContentsFields({ surveys }),
                    submitLabel: '登録する',
                  }}
                  validateOnSubmit={validateOnDone}
                  onFinish={onDoneFinished}
                />
              </>
            )}
            {['initial', 'supportRequired'].includes(surveyAnswer.status) && (
              <Button color="primary" onClick={onClickStart}>
                対応開始
              </Button>
            )}
            {surveyAnswer.status === 'supported' && (
              <Button color="primary" outline onClick={onClickUnfinish}>
                対応中に戻す
              </Button>
            )}
          </div>
          <div className="d-sm-flex justify-content-around mt-5 gap-4">
            <div className="flex-fill">
              <table className="table table-bordered">
                <tbody className="thead-light">
                  <tr>
                    <th style={{ width: thWidth }}>グループ</th>
                    <td>{surveyGroups.find((_) => _.id === survey?.surveyGroupId)?.name}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>アンケートページ</th>
                    <td>{survey?.title}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>回答日時</th>
                    <td>{formatDate(surveyAnswer.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>回答者</th>
                    <td>
                      <TenantLink to={`/admin/users/${surveyAnswer.createdBy?.uid}`}>
                        {surveyAnswer.createdBy?.displayName}
                      </TenantLink>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>メモ</th>
                    <td style={{ whiteSpace: 'pre-line' }}>
                      <div>
                        <EditButton
                          label={false}
                          color="link"
                          size="sm"
                          itemRef={surveyAnswer.ref}
                          FormModal={ModelFormModal}
                          formProps={{ title: '情報編集', fields: adminFields }}
                          beforeSubmit={(values) => ({
                            ...values,
                            adminNoteUpdatedAt: new Date(),
                            adminNoteUpdatedBy: auditData(user),
                          })}
                        />
                      </div>
                      {surveyAnswer.adminNote}
                      <br />
                      {surveyAnswer.adminNoteUpdatedAt && surveyAnswer.adminNoteUpdatedBy && (
                        <small className="text-muted">
                          {surveyAnswer.adminNoteUpdatedAt.toDate().toLocaleString()}{' '}
                          {surveyAnswer.adminNoteUpdatedBy.displayName}
                        </small>
                      )}
                    </td>
                  </tr>
                  {relatedQuestions.map((question) => {
                    const { id, type, name, description } = question;
                    const answer = surveyAnswer.answers[id];
                    const displayValue = {
                      text: answer,
                      checkbox: keys(answer || {}).join(', '),
                      radio: answer,
                      select: answer,
                      imageFile: [answer].flat().map((answer, i) => (
                        <div key={i}>
                          <a href={answer?.url} target="_blank">
                            {answer?.name}
                          </a>
                        </div>
                      )),
                    }[type];
                    return (
                      <tr key={id}>
                        <th key={id} style={{ width: thWidth }}>
                          {name}
                          <div className="text-muted small">{description}</div>
                        </th>
                        <td>{displayValue}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-5 mt-sm-0" style={{ minWidth: '40vw', maxWidth: '50vw', }}>
              <div>
                <div className="d-flex justify-content-end mb-3">
                  <AddInTenantButton
                    label="新規コメント"
                    itemRef={commentsRef.doc()}
                    processValues={(_) => ({ ..._, createdBy: pick(user, ['uid', 'email', 'displayName']) })}
                    FormModal={ModelFormModal}
                    formProps={{ title: '新規コメント', fields: commentFields }}
                  />
                </div>
                <div className="overflow-auto" style={{ maxHeight: 360 }}>
                  {comments.length > 0 ? (
                    <div>
                      {comments.map(({ id, body, createdAt, createdBy }) => {
                        return (
                          <div key={id} className="card p-3 mb-3">
                            <div className="small text-muted mb-1">
                              {createdBy.displayName} {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                            </div>
                            <div style={{ whiteSpace: 'pre-line' }}>{body}</div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>コメントはまだありません</div>
                  )}
                </div>
              </div>
              <hr className="my-5" />
              <div>
                <h5>アクティビティ</h5>
                <div className="overflow-auto" style={{ maxWidth: '50vw', maxHeight: 360 }}>
                  {activities.length > 0 ? (
                    <div className="d-flex flex-column gap-1">
                      {orderBy(activities, (_) => _.createdAt.toDate(), 'desc').map((_) => (
                        <Activity activity={_} activityTypes={activityTypes} />
                      ))}
                    </div>
                  ) : (
                    <div>アクティビティはまだありません</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});
