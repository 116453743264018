import React from 'react';
import { sortBy, isEmpty, uniq, omit, keyBy, maxBy, orderBy } from 'lodash';
import { format as formatDate, startOfDay, endOfDay, addMonths, addDays, } from 'date-fns';
import numeral from 'numeral';
import ellipsis from 'text-ellipsis';

import {
  statuses,
  fields as _fields,
  destinationFields,
  shipmentFields,
  reimbursementResultFields,
  discountFields,
} from '../../shared/models/troubleInquiry';
import firebase from '../../firebase';
import { areaFromPostalCode } from '../../shared/models/setting';
import { fieldDisplayValue } from '../../shared/util';
import { supportMeans as supportMeanOptions } from '../../shared/config';
import AdminPage from '../hocs/AdminPage';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import ExportButton from '../ExportButton';
import QueryText from '../QueryText';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import TenantLink from '../TenantLink';
import useTenant from '../hooks/useTenant';
import {
  troubleInquiryRef,
} from '../../models/troubleInquiry';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';
import { useProductTypeCollection, tenantProductTypesQuery } from '../../models/productType';
import { useProductCollection, tenantProductsQuery } from '../../models/product';
import {
  useTroubleInquiryCommentCollectionsOnce,
  tenantLimitToLastTroubleInquiryCommentsQuery,
} from '../../models/troubleInquiryComment';
import { useAgentCollection, tenantAgentsQuery } from '../../models/agent';
import { useAgentShopCollection, tenantAgentShopsGroupQuery } from '../../models/agentShop';
import QueryDateRangeSelector from '../QueryDateRangeSelector';

const { keys, entries } = Object;
const db = firebase.firestore();
const ordersRef = db.collection('orders');

export default AdminPage(function AdminTroubleInquiries(props) {
  const tenant = useTenant();
  const { user } = props;
  const queryParams = useQueryParams();
  const {
    text = '',
    field: [field] = ['email'],
    picNames: picNamesForFilter,
    statuses: statusesForFilter,
    reimbursementOnly: _reimbursementOnly = '0',
    discountOnly: _discountOnly = '0',
    approvalRequiredOnly: _approvalRequiredOnly = '0',
    dateRange,
  } = queryParams;
  const startOn = queryParams.dateRange?.[0] ? new Date(queryParams.dateRange[0]) : addDays(addMonths(new Date(), -6), 1);
  const endOn = queryParams.dateRange?.[1] ? new Date(queryParams.dateRange[1]) : new Date();
  const reimbursementOnly = _reimbursementOnly === '1';
  const discountOnly = _discountOnly === '1';
  const approvalRequiredOnly = _approvalRequiredOnly === '1';
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const { data: productTypes = [] } = useProductTypeCollection(tenantProductTypesQuery(tenant?.id));
  const { data: products = [] } = useProductCollection(tenantProductsQuery(tenant?.id));
  const productsById = keyBy(products, 'id');
  const fields = {
    ...omit(_fields({ productTypes, products }), ['destinationType']),
    ...shipmentFields(),
  };
  const troubleInquiriesRef = text ? (
    db.collection('troubleInquiries')
      .where(field, '>=', text)
      .where(field, '<=', text + '\uf8ff')
      .orderBy(field, 'asc')
  ) : (
    db.collection('troubleInquiries').where('createdAt', '>=', startOfDay(startOn)).where('createdAt', '<=', endOfDay(endOn)).orderBy('createdAt', 'desc')
  );
  const troubleInquiries = useCollectionSubscriptionInTenant(troubleInquiriesRef, [text, ...[startOn, endOn].map(_ => formatDate(_, 'yyyy/MM/dd'))]);
  const questions = sortBy(useCollectionSubscriptionInTenant(db.collection('questions')), (_) => _.createdAt.toDate());
  const surveys = useCollectionSubscriptionInTenant(db.collection('surveys').where('surveyGroupId', 'in', ['troubleInquiry', 'troubleReinquiry']));
  const surveysById = keyBy(surveys, 'id');
  const answeredSurveys = uniq(troubleInquiries.flatMap((troubleInquiry) => {
    const product = productsById[troubleInquiry.productId];
    return [product?.troubleInquirySurveyId, product?.troubleReinquirySurveyId].filter(_ => _);
  })).map(_ => surveysById[_]);
  const answeredQuestionIds = troubleInquiries.map(_ => Object.keys(_.answers | {})).flat()
  const relatedQuestionIds = uniq([...answeredSurveys.flatMap(_ => _?.questionRows?.map(_ => _.questionId) || []), ...answeredQuestionIds].flat());
  const relatedQuestions = questions.filter(_ => relatedQuestionIds.includes(_.id));
  const lastCommentQueries = tenant
    ? troubleInquiries.map(({ id }) =>
        tenantLimitToLastTroubleInquiryCommentsQuery(troubleInquiryRef(id), tenant.id, 1)
      )
    : [];
  const { data: lastComments = [] } = useTroubleInquiryCommentCollectionsOnce(lastCommentQueries);
  const lastCommentsByTroubleInquiryId = keyBy(lastComments, ({ ref }) => ref.parent.parent.id);
  const { data: agentShops = [] } = useAgentShopCollection(tenantAgentShopsGroupQuery(tenant?.id));
  const agentShopsById = keyBy(agentShops, 'id');
  const { data: agents = [] } = useAgentCollection(tenantAgentsQuery(tenant?.id));
  const agentsById = keyBy(agents, 'id');
  const rows = troubleInquiries.map((troubleInquiry) => {
    const { items, destinationPostalCode, respondedBy, wholesaleAgentShopId, wholesaleAgentId } = troubleInquiry;
    const area = areaFromPostalCode(destinationPostalCode, areaSetting);
    const agentShop = agentShopsById[wholesaleAgentShopId];
    const agent = agentsById[wholesaleAgentId];
    const itemsWithProduct = items.map((item) => {
      const { productId } = item;
      const product = productsById[productId];
      return { ...item, product };
    });
    const picName = respondedBy?.displayName || area?.user.displayName;
    const { body: lastComment = '' } = lastCommentsByTroubleInquiryId[troubleInquiry.id] || {};
    return {
      troubleInquiry,
      agentShop,
      agent,
      itemsWithProduct,
      area,
      picName,
      lastComment: lastComment,
    };
  });
  const picNameOptions = uniq(rows.map((_) => _.picName))
    .filter((_) => _)
    .map((_) => ({ label: _, value: _ }));
  const statusOptions = entries(statuses).map(([value, { label }]) => ({
    label,
    value,
  }));
  const fieldOptions = [
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウントID', value: 'createdBy.uid' },
  ];

  // NOTE: filter
  let filteredRows = rows;
  if (!isEmpty(picNamesForFilter)) {
    filteredRows = filteredRows.filter((_) => picNamesForFilter?.includes(_.picName));
  }
  if (!isEmpty(statusesForFilter)) {
    filteredRows = filteredRows.filter((_) => statusesForFilter?.includes(_.troubleInquiry.status));
  }
  if (reimbursementOnly) {
    filteredRows = filteredRows.filter((_) => _.troubleInquiry.hasReimbursement);
    filteredRows = orderBy(
      filteredRows,
      [({ troubleInquiry: { reimbursementResult } }) => reimbursementResult?.date.toDate() || 0],
      ['desc']
    );
  }
  if (discountOnly) {
    filteredRows = filteredRows.filter((_) => _.troubleInquiry.discount);
    filteredRows = orderBy(
      filteredRows,
      [({ troubleInquiry: { discount } }) => discount?.date.toDate() || 0],
      ['desc']
    );
  }
  if (approvalRequiredOnly) {
    filteredRows = filteredRows.filter((_) => _.troubleInquiry.approvalRequired);
    filteredRows = orderBy(filteredRows, [({ troubleInquiry: { approvedAt } }) => approvedAt?.toDate()], ['desc']);
  }

  const rowsForExport = () => {
    const maxLengthItemsRow = maxBy(filteredRows, (_) => _.itemsWithProduct.length);
    return filteredRows.map(({ troubleInquiry, itemsWithProduct, area, agent, agentShop, lastComment }) => {
      const {
        id,
        status,
        sourceOrder,
        createdAt,
        respondedBy,
        reimbursementResult,
        discount,
        discountRequest,
        approvedAt,
        approvalRequestComment,
        approvalOrRejectionComment,
        supportMeans,
        supportSummary,
      } = troubleInquiry;
      return {
        id,
        status,
        createdAt: formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        sourceOrderedAt: sourceOrder != null && formatDate(sourceOrder.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        areaStaff: area?.user.displayName,
        agentId: agent?.id,
        agentName: agent?.name,
        agentShopId: agentShop?.id,
        agentShopName: agentShop?.name,
        respondedBy: respondedBy?.displayName,
        ...(
          relatedQuestions.reduce((x, question) => {
            const { id, name, type, } = question;
            const answer = troubleInquiry.answers?.[id];
            const value = ({
              checkbox: keys(answer || {}),
              imageFile: [answer].flat().map(_ => _?.url).join('\n'),
            })[type] || answer;
            return {
              ...x,
              [name]: value,
            };
          }, {})
        ),
        ...entries(fields).reduce((x, [fieldName, fieldSettings]) => {
          return {
            ...x,
            [fieldName]: fieldDisplayValue(troubleInquiry[fieldName], fieldSettings),
          };
        }, {}),
        ...maxLengthItemsRow.itemsWithProduct.reduce((x, _, i) => {
          const item = itemsWithProduct[i];
          return {
            ...x,
            [`item_${i}_productId`]: item?.product?.id,
            [`item_${i}_productCode`]: item?.product?.code,
            [`item_${i}_productName`]: item?.product?.name,
            [`item_${i}_quantity`]: item?.quantity,
          };
        }, {}),
        reimbursementDate: reimbursementResult?.date && formatDate(reimbursementResult.date.toDate(), 'yyyy/MM/dd'),
        reimbursementAmount: reimbursementResult?.amount,
        reimbursementNote: reimbursementResult?.note,
        discountDate: discount?.date && formatDate(discount.date.toDate(), 'yyyy/MM/dd'),
        discountAmount: discount?.amount,
        discountNote: discount?.note,
        discountApprovedAt:
          discountRequest?.approvedAt && formatDate(discountRequest.approvedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        discountApprovalOrRejectionComment: discountRequest?.approvalOrRejectionComment,
        approvedAt: approvedAt && formatDate(approvedAt.toDate(), 'yyyy/MM/dd'),
        approvalRequestComment,
        approvalOrRejectionComment,
        lastComment,
        supportMeans: supportMeans?.map((_) => supportMeanOptions[_]),
        supportSummary,
      };
    });
  };

  return (
    <div>
      <div className="admin-trouble-inquiries container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>不具合組立問合せ一覧</h4>
        </div>
        <div className="d-flex flex-wrap gap-3 mb-2">
          <QueryDateRangeSelector label="期間" defaultValue={[startOn, endOn]} paramName="dateRange" pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }} disabled={!!text} />
          <div className="d-flex flex-wrap align-items-end gap-1">
            <QuerySelector
              paramName="field"
              options={fieldOptions}
              label="検索フィールド"
              defaultValue={[field]}
              isClearable={false}
            />
            <QueryText paramName="text" label="検索テキスト" />
          </div>
        </div>
        <div className="d-flex flex-wrap gap-1 mb-3 align-items-end">
          <QuerySelector paramName="picNames" width={250} isMulti options={picNameOptions} label="担当で絞込み" />
          <QuerySelector paramName="statuses" width={250} isMulti options={statusOptions} label="状態で絞込み" />
          <QueryBoolean paramName="reimbursementOnly" label="立替有のみ表示" defaultValue={'0'} />
          <QueryBoolean paramName="discountOnly" label="割引有のみ表示" defaultValue={'0'} />
          <QueryBoolean paramName="approvalRequiredOnly" label="要承認のみ表示" defaultValue={'0'} />
        </div>
        <div className="d-flex justify-content-end mb-3">
          <ExportButton
            className="ml-2"
            fileName="不具合組立問合せ.csv"
            rows={rowsForExport}
            hasPersonalInfo
            user={user}
          />
        </div>
        <div className="overflow-auto">
          {rows.length > 0 ? (
            <table className="table table-bordered">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th style={{ minWidth: 150 }}>ID</th>
                  <th style={{ minWidth: 80 }}>状態</th>
                  <th style={{ minWidth: 150 }}>お問合せ日時</th>
                  <th style={{ minWidth: 150 }}>元受注日時</th>
                  <th style={{ minWidth: 150 }}>担当</th>
                  <th style={{ minWidth: 150 }}>代理店</th>
                  <th style={{ minWidth: 150 }}>店舗</th>
                  <th style={{ minWidth: 150 }}>都道府県</th>
                  <th style={{ minWidth: 150 }}>市区町村</th>
                  <th style={{ minWidth: 150 }}>お名前</th>
                  <th style={{ minWidth: 150 }}>商品種別</th>
                  <th style={{ minWidth: 150 }}>商品</th>
                  <th style={{ minWidth: 400 }}>パーツ</th>
                  <th style={{ minWidth: 400 }}>発送メールでのユーザーへのメッセージ</th>
                  <th style={{ minWidth: 150 }}>購入時期(年)</th>
                  <th style={{ minWidth: 150 }}>購入時期(月)</th>
                  {relatedQuestions.map((question) => {
                    const { id, name, type } = question;
                    return (
                      <th key={id} style={{ ...(type === 'text' ? { minWidth: 300, } : { minWidth: 200, maxWidth: 300 }), }}>
                        {name}
                      </th>
                    );
                  })}
                  <th style={{ minWidth: 150 }}>電話番号</th>
                  <th style={{ minWidth: 150 }}>メールアドレス</th>
                  <th style={{ minWidth: 150 }}>郵便番号</th>
                  <th style={{ minWidth: 150 }}>番地・建物名</th>
                  {entries(destinationFields()).map(([fieldName, { label }]) => {
                    return (
                      <th key={fieldName} style={{ minWidth: 150 }}>
                        配送先{label}
                      </th>
                    );
                  })}
                  {entries(reimbursementResultFields()).map(([fieldName, { label }]) => {
                    const minWidth = fieldName === 'note' ? 400 : 150;

                    return (
                      <th key={fieldName} style={{ minWidth }}>
                        {label}
                      </th>
                    );
                  })}
                  {entries(discountFields()).map(([fieldName, { label }]) => {
                    const minWidth = fieldName === 'note' ? 400 : 150;

                    return (
                      <th key={fieldName} style={{ minWidth }}>
                        {label}
                      </th>
                    );
                  })}
                  <th style={{ minWidth: 150 }}>割引 - 承認日</th>
                  <th style={{ minWidth: 400 }}>割引 - 承認/否認コメント</th>
                  <th style={{ minWidth: 150 }}>承認日</th>
                  <th style={{ minWidth: 400 }}>承認申請コメント</th>
                  <th style={{ minWidth: 400 }}>承認/否認コメント</th>
                  <th style={{ minWidth: 400 }}>対応コメント</th>
                </tr>
              </thead>
              <tbody>
                {filteredRows.map((_) => <TroubleInquiryRow key={_.troubleInquiry.id} {..._} fields={fields} relatedQuestions={relatedQuestions} />)}
              </tbody>
            </table>
          ) : (
            <div>不具合組立問合せは未登録です</div>
          )}
        </div>
      </div>
    </div>
  );
});

const TroubleInquiryRow = ({ troubleInquiry, itemsWithProduct, picName, agent, agentShop, lastComment, fields, relatedQuestions }) => {
  const {
    id,
    createdAt,
    sourceOrder,
    status,
    createdBy,
    approvalStatus,
    approvedAt,
    approvalRequestComment,
    approvalOrRejectionComment,
    discountRequest,
    orderId,
  } = troubleInquiry;
  const { label: statusLabel, color } = statuses[status] || {};
  const shipmentOrder = useDocumentSubscription(orderId && ordersRef.doc(orderId), [orderId]);
  return (
    <tr key={id}>
      <td>
        <TenantLink to={`/admin/troubleInquiries/${id}`}>{id}</TenantLink>
      </td>
      <td>
        <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
        {shipmentOrder
          ? (shipmentOrder?.cammacsStatus === 'shipped'
            ? <span className="badge badge-success">発送完了</span>
            : <span className="badge badge-info">発送依頼中</span>)
          : ''
        }
        {approvalStatus === 'requested' && <span className="badge badge-danger">承認待ち</span>}
      </td>
      <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
      <td>
        {sourceOrder != null && formatDate(sourceOrder.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
      </td>
      <td>{picName}</td>
      <td>{agent?.name}</td>
      <td>{agentShop?.name}</td>
      {['prefecture', 'city', 'name', 'productTypeId', 'productId'].map((fieldName) => {
        const displayValue = fieldDisplayValue(troubleInquiry[fieldName], fields[fieldName]);
        return (
          <td key={fieldName}>
            {fieldName === 'name' ? (
              <TenantLink to={`/admin/users/${createdBy.uid}`}>{displayValue}</TenantLink>
            ) : (
              ellipsis(displayValue, 80)
            )}
          </td>
        );
      })}
      <td>
        {itemsWithProduct.map((item) => {
          const { product, quantity } = item;
          return (
            product != null && (
              <div key={product.id}>
                {status === 'initial' && quantity > product.normalInventory && (
                  <span className="mr-1 text-danger small">[在庫不足]</span>
                )}
                <span>{product.code}</span>
                <span className="ml-2">{product.name}</span>
                <span className="ml-2">{numeral(quantity).format('0,0')}個</span>
              </div>
            )
          );
        })}
      </td>
      {[
        'shipmentMessage',
        'purchaseYear',
        'purchaseMonth',
      ].map((fieldName) => {
        return (
          <td key={fieldName}>
            {ellipsis(fieldDisplayValue(troubleInquiry[fieldName], fields[fieldName]), 80)}
          </td>
        );
      })}
      {relatedQuestions.map((question) => {
        const { id, type } = question;
        const answer = troubleInquiry.answers?.[id];
        const displayValue = {
          text: ellipsis(answer ?? '', 80),
          checkbox: keys(answer || {}).join(', '),
          radio: answer,
          select: answer,
          imageFile: [answer].flat().map((answer, i) => (
            <div key={i}>
              <a href={answer?.url} target="_blank">
                {answer?.name}
              </a>
            </div>
          )),
        }[type];
        return <td key={id}>{displayValue}</td>;
      })}
      {[
        'phone',
        'email',
        'postalCode',
        'address',
        ...keys(destinationFields()),
      ].map((fieldName) => {
        return (
          <td key={fieldName}>
            {ellipsis(fieldDisplayValue(troubleInquiry[fieldName], fields[fieldName]), 80)}
          </td>
        );
      })}
      {entries(reimbursementResultFields()).map(([fieldName, fieldSetting]) => {
        return (
          <td key={fieldName}>
            {fieldDisplayValue(troubleInquiry.reimbursementResult?.[fieldName], fieldSetting)}
          </td>
        );
      })}
      {entries(discountFields()).map(([fieldName, fieldSetting]) => {
        return (
          <td key={fieldName}>
            {fieldDisplayValue(troubleInquiry.discount?.[fieldName], fieldSetting)}
          </td>
        );
      })}
      <td>
        {discountRequest?.approvedAt &&
          formatDate(discountRequest.approvedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
      </td>
      <td>{discountRequest?.approvalOrRejectionComment}</td>
      <td>{approvedAt && formatDate(approvedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
      <td>{approvalRequestComment}</td>
      <td>{approvalOrRejectionComment}</td>
      <td>{lastComment}</td>
    </tr>
  );
}
