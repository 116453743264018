import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { sortBy, get, pick, omit } from 'lodash';
import { useToggle } from 'react-use';
import qs from 'qs';
import retry from 'async-retry';

import firebase, { functions } from '../../firebase';
import texts from '../../shared/texts';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { shippingDestination } from '../../shared/models/inquiry';
import { generateInquiryId } from '../../shared/util';
import TenantUserPage from '../hocs/TenantUserPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import NewInquiryForm from '../forms/NewInquiryForm';
import NewInquiryConfirmForm from '../forms/NewInquiryConfirmForm';

const storageRef = firebase.storage().ref();
const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const inquiriesRef = db.collection('inquiries');
const inquiryTypesRef = db.collection('inquiryTypes');
const inquiryTypeChildrenRef = db.collection('inquiryTypeChildren');
const createUserAndSendEmailVerification = functions.httpsCallable('createUserAndSendEmailVerification');

function Page(props) {
  const {
    user,
    myAccount,
    history,
    firebaseUser,
    toggleLoginForm,
    match: {
      params: { tenantPath },
    },
  } = props;
  const { sourceInquiryId, sourceSurveyAnswerId } = useQueryParams();
  const [isProceeded, toggleProceeded] = useToggle(false);
  const [showsConfirm, toggleConfirm] = useToggle(false);
  const [values, setValues] = useState({});
  const inquiryTypes = sortBy(useCollectionSubscriptionInTenant(inquiryTypesRef), 'index');
  const inquiryTypeChildren = sortBy(useCollectionSubscriptionInTenant(inquiryTypeChildrenRef), 'index');
  const sourceInquiry = useDocumentSubscription(sourceInquiryId && inquiriesRef.doc(sourceInquiryId), [sourceInquiryId]);
  const onSubmitNewInquiryForm = async (_values) => {
    setValues({ ...values, ..._values });
    toggleConfirm(true);
  };
  const onSubmitConfirm = async (_values) => {
    const userValues = await createOrUpdateUser(_values);
    const destination = shippingDestination(_values);

    try {
      const id = await generateInquiryId();
      const processedFiles = await Promise.all(
        Array.from(values.files || []).map(async (file) => {
          const fileRef = storageRef.child(`inquiries/${id}/files/${new Date().toISOString()}/${file.name}`);
          await fileRef.put(file, { contentType: file.type });
          return {
            ...pick(file, ['name', 'type']),
            url: await fileRef.getDownloadURL(),
          };
        })
      );
      await inquiriesRef.doc(id).set({
        ...omit(_values, ['id', 'password', 'files']),
        ...destination,
        tenantId: tenantPath,
        files: processedFiles,
        createdAt: new Date(),
        createdBy: userValues ? omit(userValues, 'ref') : null,
        myAccount: myAccount || null,
        status: 'initial',
        sourceInquiryId: sourceInquiryId || null,
        sourceSurveyAnswerId: sourceSurveyAnswerId || null,
      });

      history.push(`/${tenantPath}/inquiries/thanks`);
    } catch (error) {
      console.error(error);
      toast.error('失敗しました');
    }
  };
  const createOrUpdateUser = async (_values) => {
    if (user != null) {
      // NOTE: 更新
      await user.ref.update({ ...pick(_values, ['phone', 'prefecture', 'nameKana', 'postalCode', 'city', 'address']) });
      return user;
    } else {
      const { email, password, registersAccount, name: displayName } = _values;
      if (!registersAccount) return;

      // NOTE: アカウント登録
      try {
        const userValues = {
          displayName,
          ...pick(_values, ['email', 'phone', 'prefecture', 'nameKana', 'postalCode', 'city', 'address']),
        };
        const {
          data: { uid },
        } = await retry(
          (_) =>
            createUserAndSendEmailVerification({
              userValues,
              password,
              pathname: encodeURIComponent('/mypage/profile'),
              skipsEmailVerification: true,
            }),
          { maxTimeout: 1000 }
        );
        try {
          await auth.signInWithEmailAndPassword(email, password);
        } catch (e) {
          // NOTE: 最悪ログインは失敗しても問題ないのでスルー
          console.error(e);
        }
        return { uid, ...userValues };
      } catch (e) {
        console.error(e);
        const code = get(e, 'details.code') || e.code;
        const message = userErrorMessages[code] || '登録に失敗しました';
        toast.error(message);
        throw e;
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showsConfirm]);

  return (
    <div className="new-inquiry position-relative">
      {!showsConfirm ? (
        <NewInquiryForm
          onSubmit={onSubmitNewInquiryForm}
          values={values}
          user={user}
          onClickLogin={toggleLoginForm}
          {...{ isProceeded, toggleProceeded, inquiryTypes, inquiryTypeChildren, sourceInquiry }}
        />
      ) : (
        <NewInquiryConfirmForm
          values={values}
          onSubmit={onSubmitConfirm}
          onClickBack={toggleConfirm.bind(null, false)}
          {...{ user, inquiryTypes, inquiryTypeChildren }}
        />
      )}
    </div>
  );
}

export default function NewInquiry(props) {
  const { user } = props;
  const queryParams = useQueryParams();
  Page.preview = !user && queryParams.sourceInquiryId == null && queryParams.sourceSurveyAnswerId == null;
  const Component = TenantUserPage(Page)
  return <Component {...props} />;
};
