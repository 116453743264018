import React, { useEffect } from 'react';
import { sortBy, keyBy, groupBy, range } from 'lodash';
import { format as formatDate, addHours } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import classnames from 'classnames';
import ja from 'date-fns/locale/ja';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import useAppTitle from '../hooks/useAppTitle';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useTenant from '../hooks/useTenant';
import useQueryParams from '../hooks/useQueryParams';
import LectureSelector from '../LectureSelector';
import TenantLink from '../TenantLink';
import LectureDetailModal from '../modals/LectureDetailModal';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import ModalButton from '../ModalButton';
import PicsFormModal from '../modals/PicsFormModal';
import { staffRoles } from '../../shared/config';

const db = firebase.firestore();
const usersRef = db.collection('users');
const eventsRef = db.collection('events');

export default AdminPage(function AdminLecture(props) {
  const {
    match: {
      params: { eventId, lectureId },
    },
    addBreadNavValues,
  } = props;
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const lectures = useCollectionSubscriptionInTenant(eventRef.collection('lectures'), [eventId]);
  const lecture = lectures.find(_ => _.id === lectureId);
  useAppTitle(['ADMIN', event?.name, (_ => _ && formatDate(_, 'yyyy/MM/dd'))(lecture?.date.toDate()), 'スケジュール'].join(' '));
  const eventProductTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('eventProductTypes')), _ => _.createdAt.toDate());
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), _ => _.createdAt.toDate());
  const eventProductTypesById = keyBy(eventProductTypes, 'id');
  const lectureTypesById = keyBy(lectureTypes, 'id');
  const entries = useCollectionSubscription(eventRef.collection('entries').where('lectureIds', 'array-contains', lectureId).orderBy('createdAt'), [eventId, lectureId]);
  const entryFrames = entries.map((e) => e.frames.map((_) => ({ ..._, entry: e }))).reduce((x, y) => [...x, ...y], []);
  const entryFramesGroupedByFrameIndex = groupBy(
    entryFrames.filter((_) => _.lectureId === lectureId),
    'frameIndex'
  );
  const { frames: lectureFrames = [] } = lecture || {};
  const staffs = useCollectionsFetchInTenant(['admin', 'staff'].map(_ => usersRef.where('role', '==', _)));
  useEffect(() => {
    addBreadNavValues({ event, lecture });
  }, [event, lecture]);

  const entryIds = lectureFrames
    .map((lectureFrame, frameIndex) => {
      const { capacity = 0 } = lectureFrame;
      const entryFrames = entryFramesGroupedByFrameIndex[frameIndex] || [];
      return [
        ...range(0, capacity).map((seatIndex) => {
          const { entry } = entryFrames.filter((_) => _.entry?.cancelledAt == null)[seatIndex] || {};
          return entry?.id;
        }),
        ...entryFrames
          .filter((_) => _.entry?.cancelledAt != null)
          .map((entryFrame, i) => {
            const { entry } = entryFrame;
            return entry?.id;
          }),
      ];
    })
    .flat()
    .filter((_) => _);

  return (
    <div>
      <div className="admin-event container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <LectureSelector {...{ lectureId, eventProductTypesById, lectureTypesById, lectures }} />
        </div>
        <div>
          {lectureFrames.map((lectureFrame, frameIndex) => {
            const { startAt, endAt, capacity = 0, lectureType, pics } = lectureFrame;
            const entryFrames = entryFramesGroupedByFrameIndex[frameIndex] || [];
            const onSubmitEditPics = async ({ pics }) => {
              try {
                const frames = [...lectureFrames.slice(0, frameIndex), { ...lectureFrame, pics }, ...lectureFrames.slice(frameIndex + 1)];
                await lecture.ref.update({ frames });
                toast.success('担当者を更新しました');
              } catch(e) { 
                console.error(e);
                toast.error('失敗しました');
              }
            }
            return (
              <div key={frameIndex} className="card p-3 my-2">
                <div className="d-flex align-items-center mb-2 gap-2">
                  <div>
                    {formatDate(startAt.toDate(), 'HH:mm')} 〜 {formatDate(endAt.toDate(), 'HH:mm')}
                  </div>
                  <div>{lectureTypesById[lectureType]?.name}</div>
                  <duv>{(pics || []).map(({ picId, staffRole}) => staffs.find(_ => _.id === picId)?.displayName + (staffRole ? `(${staffRoles[staffRole].label})` : '')).join(',')}</duv>
                  <ModalButton Modal={PicsFormModal} size="sm" modalProps={{ pics, staffs, onSubmit: onSubmitEditPics }}>
                    <span className="fas fa-edit" />
                  </ModalButton>
                </div>
                <div className="d-flex align-items-center flex-wrap">
                  {range(0, capacity).map((seatIndex) => {
                    return (
                      <LectureItem
                        key={seatIndex}
                        {...{ event, entries, lecture, lectureFrame, entryFrames, seatIndex, entryIds, eventProductTypesById, lectureTypesById, }}
                        {...props}
                      />
                    );
                  })}
                  {entryFrames
                    .filter((_) => _.entry?.cancelledAt != null)
                    .map((entryFrame, i) => {
                      return <CancelledLectureItem key={i} {...{ event, entries, lecture, entryFrame, entryIds, eventProductTypesById, lectureTypesById, }} {...props} />;
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

function LectureItem({ event, entries, lecture, lectureFrame, entryFrames, seatIndex, entryIds, user, history, eventProductTypesById, lectureTypesById, }) {
  const { entryId } = useQueryParams();
  const tenant = useTenant();
  const { name, entry } = entryFrames.filter((_) => _.entry?.cancelledAt == null)[seatIndex] || {};
  const { priorCapacity = 0 } = lectureFrame;
  const { checkedInAt, abortedAt, isTheDay, isTemporary, } = entry || {};
  const isPrior = seatIndex < priorCapacity;
  const isCheckedIn = checkedInAt != null;
  const Container = entry ? 'button' : 'span';
  const entryIndex = entries.findIndex(_ => _.id === entry?.id) + 1;
  const onClickTemp = async (e) => {
    e.stopPropagation();
    if(!window.confirm(`キャンセルします。よろしいですか？`)) return;
    
    try {
      await entry.ref.delete();
      toast.success('キャンセルしました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <>
      <Container
        className={classnames('card p-2 m-1 mb-2 text-center position-relative d-flex flex-column justify-content-center', {
          'border-info': isPrior,
        })}
        style={{ width: 140, height: 77, lineHeight: 1.3 }}
        onClick={() => entry && history.push(`/${tenant.id}/admin/events/${event.id}/lectures/${lecture.id}?entryId=${entry.id}`)}
      >
        <div className="position-absolute" style={{ top: -10, left: 0 }}>
          {entryIndex > 0 && (
            <span className="badge badge-pill badge-info">
              {event?.entryIndexPrefix}{entryIndex}
             </span>
          )}
          {isCheckedIn && (
            <span className="badge badge-pill badge-info">
              チェックイン済
            </span>
          )}
        </div>
        <div className="position-absolute" style={{ bottom: -10, left: 0 }}>
          {isTheDay && (
            <span className="badge badge-pill badge-secondary">
              当日
            </span>
          )}
          {isTemporary && (
            <span className="badge badge-pill badge-secondary cursor-pointer" onClick={onClickTemp}>
              仮押さえ中
            </span>
          )}
          {abortedAt != null && (
            <span className="badge badge-pill badge-secondary">
              中止済み
            </span>
          )}
        </div>
        <span className="mt-1" style={{ color: entry ? '#007bff' : '' }}>{name}</span>
        <span className="text-muted small">{entry && entry.createdBy.displayName}</span>
        {entry != null && entry.createdAt.toDate() > addHours(new Date(), -60) && (
          <span className="text-muted mt-1 text-right" style={{ fontSize: 9 }}>
            {formatDistanceToNow(entry.createdAt.toDate(), { locale: ja })}前
          </span>
        )}
      </Container>
      {entry != null && (
        <LectureDetailModal
          isOpen={entry.id === entryId}
          onClickClose={() => history.push(`/${tenant.id}/admin/events/${event.id}/lectures/${lecture.id}`)}
          {...{ event, user, entry, lecture, entryIds, history, eventProductTypesById, lectureTypesById, }}
        />
      )}
    </>
  );
}

function CancelledLectureItem({ event, entries, lecture, entryFrame, entryIds, user, history, eventProductTypesById, lectureTypesById, }) {
  const { entryId } = useQueryParams();
  const tenant = useTenant();
  const { name, entry } = entryFrame;
  const Container = entry ? 'button' : 'span';
  const entryIndex = entries.findIndex(_ => _.id === entry?.id) + 1;
  return (
    <>
      <Container
        className={classnames(
          'card p-2 m-1 text-center position-relative d-flex flex-column justify-content-center bg-light-grey'
        )}
        style={{ width: 140, height: 70, lineHeight: 1.3, opacity: 0.6 }}
        onClick={() => entry && history.push(`/${tenant.id}/admin/events/${event.id}/lectures/${lecture.id}?entryId=${entry.id}`)}
      >
        {entryIndex > 0 && (
          <span className="badge badge-pill badge-info position-absolute" style={{ top: -10, left: 0 }}>
            {event?.entryIndexPrefix}{entryIndex}
           </span>
        )}
        <span className="badge badge-pill badge-secondary position-absolute" style={{ top: -10, left: 30 }}>
          キャンセル済み
        </span>
        <span className="mt-1" style={{ color: entry ? '#007bff' : '' }}>{name}</span>
        <span className="text-muted small">{entry && entry.createdBy.displayName}</span>
        {entry.cancelledAt.toDate() > addHours(new Date(), -60) && (
          <span className="text-muted mt-1 text-right" style={{ fontSize: 9 }}>
            {formatDistanceToNow(entry.cancelledAt.toDate(), { locale: ja })}前
          </span>
        )}
      </Container>
      {entry != null && (
        <LectureDetailModal
          isOpen={entry.id === entryId}
          onClickClose={() => history.push(`/${tenant.id}/admin/events/${event.id}/lectures/${lecture.id}`)}
          {...{ event, user, entry, lecture, entryIds, history, eventProductTypesById, lectureTypesById, }}
        />
      )}
    </>
  );
}
