import React, { Fragment } from 'react';
import { format as formatDate } from 'date-fns';
import { mapValues, isEmpty, get, omit, orderBy, keyBy } from 'lodash';
import { Button, Form, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import dedent from 'dedent';
import { useToggle } from 'react-use';
import { Link } from 'react-router-dom';

import firebase, { functions } from '../../firebase';
import { fields as cancelRequestFields, reasons } from '../../shared/models/cancelRequest';
import { batch } from '../../shared/firebase';
import { fields, payerFields, contactorFields } from '../../shared/models/order';
import { fieldDisplayValue } from '../../shared/util';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useFormState from '../hooks/useFormState';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import UserPage from '../hocs/UserPage';
import AddInTenantButton from '../AddInTenantButton';
import AppCard from '../AppCard';
import AppButton from '../AppButton';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';
import ProgressButton from '../ProgressButton';
import TenantLink from '../TenantLink';
import Field from '../Field';
import TenantContext from '../contexts/tenant';
import RichTextContent from '../RichTextContent';

const { entries } = Object;
const reasonsByLael = keyBy(reasons, 'label');
const db = firebase.firestore();
const cancelOrder = functions.httpsCallable('cancelOrder');
const ordersRef = db.collection('orders');
const productsRef = db.collection('products');
const methodInquiriesRef = db.collection('methodInquiries');
const cancelRequestsRef = db.collection('cancelRequests');

export default UserPage(function MypageOrders(props) {
  const { history, user = {} } = props;
  const tenants = useCollectionSubscription(db.collection('tenants'));
  const tenantsById = keyBy(tenants, 'id');
  const queryParams = useQueryParams();
  const { fromNewOrder } = queryParams
  const texts = useDocumentsFetch(
    tenants.map((_) => db.collection('settings').doc([_.id, 'text'].join('__'))),
    [tenants]
  );
  const thanksProducts = useDocumentsFetch(queryParams.productIds?.map(_ => db.collection('products').doc(_)), [queryParams], { initialItems: null });
  const textsByTenantId = keyBy(texts, (_) => _.id.replace('__text', ''));
  const orders = useCollectionSubscription(user.uid && ordersRef.where('createdBy.uid', '==', user.uid), [user.uid]);
  const products = useCollectionSubscription(productsRef);
  const productsById = keyBy(products, 'id');
  const filteredOrders = orders.filter((_) => !_.viaTroubleInquiry && !_.viaInquiry);
  const sortedOrders = orderBy(filteredOrders, (_) => _.createdAt.toDate(), 'desc');

  return (
    <div className="mypage-orders">
      <section className="container-fluid bg-light-grey">
        <h5 className="text-center mb-5 font-weight-bold p-4">注文履歴</h5>
      </section>
      {fromNewOrder === '1' && (
        <section className="container mt-4">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 d-flex flex-column gap-3">
              <div>
                <div className="text-center">
                  このページのブックマークをお薦めします。
                  <br />
                  乗り方・使い方サポート、メンテパーツ購入
                  <br />
                  注文の変更、不具合の問合せができます。
                </div>
              </div>
            </div>
          </div>
          {
            thanksProducts != null && (
              <div>
                <ModalButton
                  className="d-none"
                  isInitiallyOpen
                  hidesHeader
                  hidesFooter
                  content={({ toggleModal }) => {
                    const onClickSelf = () => {
                      if (
                        window.confirm(
                          'ご家族を登録すると、注文内容が自動表示され、組立や遊び方、不具合問合せが簡単になります。登録しますか？'
                        )
                      ) {
                        history.push('/mypage/profile?newSubAccount=1');
                      } else {
                        toggleModal(false);
                      }
                    };
                    return (
                      <div className="py-4 px-3">
                        <div className="text-center h4">ご注文ありがとうございます。</div>
                        <div>
                          <div className="text-center mt-4">
                            ご自身でお使いになりますか？
                            <br />
                            プレゼントなど、他の方がお使いになりますか？
                          </div>
                          <div className="mt-3 d-flex justify-content-around">
                            <AppButton onClick={onClickSelf}>自分で使う</AppButton>
                            <AppButton tag={Link} to="/mypage/profile?newSubAccount=1">
                              プレゼントする
                            </AppButton>
                          </div>
                          <div className="mt-5 bg-light-grey rounded-3 p-3">
                            <div>
                              お孫さんのプレゼントなど、使う方が異なる場合、事前に利用者を登録しておくことができます。
                              <br />
                              <br />
                              利用者を登録しておくと、保証を受ける際や遊び方、不具合問合せが簡単になります。
                            </div>
                            <div className="mt-3">
                              登録は<Link to="/mypage/profile?newSubAccount=1">こちら</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
                {
                  thanksProducts?.some(_ => !isEmpty(_.thanksContent)) && (
                    <ModalButton
                      className="d-none"
                      isInitiallyOpen
                      hidesHeader
                      content={({ toggleModal }) => {
                        return (
                          <div className="py-4 px-3">
                            {
                              thanksProducts?.map((product) => {
                                return (
                                  <RichTextContent
                                    key={product.id}
                                    html={product.thanksContent}
                                  />
                                );
                              })
                            }
                          </div>
                        );
                      }}
                    />
                  )
                }
              </div>
            )
          }
        </section>
      )}
      <section className="container mt-4">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="d-flex justify-content-center">
              お買上げの商品毎に、サポートしています。<br />
              注文履歴から、問合せ、パーツ購入できます。
            </div>
            <div className="mt-4">
              {sortedOrders.map((order) => {
                const tenant = tenantsById[order.tenantId];
                const texts = textsByTenantId[order.tenantId];
                return (
                  <OrderItem
                    key={order.id}
                    user={user}
                    orders={sortedOrders}
                    order={order}
                    tenant={tenant}
                    productsById={productsById}
                    texts={texts}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

function OrderItem(props) {
  const { user, orders, order, tenant, productsById, texts } = props;
  const {
    id,
    createdAt,
    amount,
    discountAmount = 0,
    shipmentFee = 0,
    orderItems = [],
    cancelledAt,
    isPreOrder = false,
    isWish = false,
    charge,
    wishToken,
  } = order;
  const isCancelled = cancelledAt != null;
  const cancelRequests = useCollectionSubscription(
    cancelRequestsRef.where('orderId', '==', id).where('createdBy.uid', '==', user.id),
    [id]
  );
  const hasBody = orderItems.some(({ productId }) => productsById[productId]?.isBody);
  const wishPaymentUrl = `${window.location.origin}/${tenant.id}/wishOrders/payment?token=${wishToken}`;

  return (
    <TenantContext.Provider value={tenant}>
      <div className="mb-4">
        <div className="position-relative" style={{ textDecoration: 'none' }}>
          <AppCard className="text-dark p-3 p-sm-4" style={{ opacity: isCancelled && 0.5 }}>
            {isWish && charge == null && (
              <div className="alert alert-warning">
                おねだり相手にこのURLを送って決済お願いします。
                <a className="d-block" href={wishPaymentUrl} target="_blank">
                  {wishPaymentUrl}
                </a>
              </div>
            )}
            <div className="card-title small font-weight-bold">注文番号 {id}</div>
            <div className="flex-grow-1">
              {isPreOrder && <div className="alert alert-info">この注文は予約注文です</div>}
              <div>
                <div className="small text-muted">注文日時</div>
                {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
              </div>
              <div className="mt-3">
                <div className="small text-muted">商品</div>
                {orderItems.map((orderItem) => {
                  const { productId, quantity } = orderItem;
                  const product = productsById[productId];
                  return (
                    product != null && (
                      <div key={productId}>
                        <div className="d-flex">
                          <div style={{ minWidth: 80 }}>
                            <img src={product.image} style={{ maxHeight: 50 }} />
                          </div>
                          <div className="flex-fill ml-2">
                            <div className="d-flex">
                              <div className="flex-fill mx-2">{product.name}</div>
                              <div className="text-nowrap">{numeral(quantity).format('0,0')}個</div>
                            </div>
                            <div className="mt-1 d-flex flex-wrap text-nowarp gap-2">
                              {product.instructionsTitle && (
                                <AppButton
                                  outline
                                  color="primary"
                                  size="sm"
                                  tag="a"
                                  target="_blank"
                                  href={product.instructionsUrl}
                                >
                                  {product.instructionsTitle}
                                  <span className="ml-1 fas fa-external-link-alt" />
                                </AppButton>
                              )}
                              <ModalButton
                                Component={AppButton}
                                outline
                                color="primary"
                                size="sm"
                                label="乗り方・使い方サポート"
                                content={({ toggleModal }) => {
                                  const onClickInquireMethod = async () => {
                                    const message = dedent`${get(texts, 'methodInquiryConfirmMessageText', '')}
                                    乗り方・使い方を問合せますか？
                                    `;
                                    if (!window.confirm(message)) return;

                                    await methodInquiriesRef.doc().set({
                                      tenantId: order.tenantId,
                                      orderId: order.id,
                                      productId,
                                      status: 'initial',
                                      createdAt: new Date(),
                                      createdBy: user.id || user.uid,
                                    });
                                    toast.success('お問合せを受付けました。');
                                    toggleModal(false);
                                  };

                                  return (
                                    <div className="d-flex flex-column align-items-stretch gap-3 p-3">
                                      <a
                                        href="https://vitamin-i.jp/start/"
                                        className="btn btn-outline-primary rounded-pill px-4"
                                        target="_blank"
                                      >
                                        サポートページへ
                                        <span className="fas fa-external-link-alt ml-1" />
                                      </a>
                                      <AppButton
                                        color="secondary"
                                        tag={TenantLink}
                                        to={`/methodInquiry?orderId=${id}&productId=${productId}&productTypeId=${product.productTypeIds?.[0]}`}
                                      >
                                        インストラクターに問い合わせる
                                      </AppButton>
                                    </div>
                                  );
                                }}
                              />
                              {product.isBody && (
                                <>
                                  <AppButton
                                    outline
                                    color="primary"
                                    size="sm"
                                    tag={TenantLink}
                                    to={`/parts?productId=${productId}&productTypeId=${product.productTypeIds?.[0]}`}
                                  >
                                    メンテパーツ購入
                                  </AppButton>
                                  <AppButton
                                    size="sm"
                                    tag={TenantLink}
                                    to={`/troubleInquiry?orderId=${id}&productId=${productId}&productTypeId=${product.productTypeIds?.[0]}`}
                                  >
                                    不具合・組立を問合せ
                                  </AppButton>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
              <div className="mt-3">
                <div className="d-flex small gap-2">
                  <div className="text-muted">お支払い合計(送料込)</div>
                  <Link to={`/mypage/orders/${order.id}/receipt`} target="_blank">
                    領収書
                  </Link>
                  {hasBody &&
                    <Link to={`/mypage/orders/${order.id}/warranty`} target="_blank">
                      保証書
                    </Link>
                  }
                </div>
                {numeral(amount - discountAmount + shipmentFee).format('0,0')}円(税込)
              </div>
              <div className="mt-3 d-flex justify-content-end">
                <div className="d-flex flex-column flex-md-row text-right gap-1">
                  {['initial', 'imported'].includes(order.cammacsStatus) && (
                    <ModalButton
                      className="rounded-pill px-4"
                      size="sm"
                      color="secondary"
                      outline
                      icon={false}
                      label="キャンセル or 内容変更を希望する"
                      style={{ color: '#777', borderColor: '#777' }}
                      disabled={cancelRequests.length > 0}
                      content={({ toggleModal }) => (
                        <CancelModalContent toggleModal={toggleModal} order={order} texts={texts} user={user} />
                      )}
                    />
                  )}
                  <DetailButton orders={orders} order={order} productsById={productsById} />
                </div>
              </div>
            </div>
          </AppCard>
          {isCancelled && (
            <div className="position-absolute fit d-flex justify-content-center align-items-center">
              <div className="text-danger border border-danger font-weight-bold px-2 py-1 bg-white">キャンセル済み</div>
            </div>
          )}
        </div>
      </div>
    </TenantContext.Provider>
  );
}

function DetailButton(props) {
  const { orders, order, productsById } = props;
  const [showsModal, toggleModal] = useToggle(false);

  return (
    <Fragment>
      <AppButton size="sm" onClick={toggleModal.bind(null, true)}>
        詳細を確認する
      </AppButton>
      {showsModal && (
        <Modal isOpen style={{ minWidth: '50vw' }}>
          <ModalHeader cssModule={{ 'modal-title': 'w-100 mb-0' }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-nowrap">注文詳細</div>
              <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
                <ModalButton
                  Component={AppButton}
                  Modal={ModelFormModal}
                  modalProps={({ toggleModal }) => {
                    const handleSubmit = async (values, { onClickClose }, targets = [order]) => {
                      if (!window.confirm('本当に変更しますか？')) return;

                      try {
                        await batch(db, targets, (batch, _) => batch.update(_.ref, values));
                        toast.success('変更しました');
                        toggleModal();
                      } catch (e) {
                        console.error(e);
                        toast.error('失敗しました');
                      }
                    };

                    return {
                      title: '利用者情報変更',
                      values: order,
                      fields: contactorFields(),
                      submitLabel: '変更する',
                      onSubmit: handleSubmit,
                      Button: AppButton,
                      renderFooterContent: (
                        base,
                        { isUnsubmittable, toggleSubmitting, statedFields, isSubmitting }
                      ) => {
                        const onSubmit = async (event) => {
                          event.preventDefault();
                          if (isUnsubmittable) return;

                          toggleSubmitting(true);
                          await handleSubmit({ ...mapValues(statedFields, 'value') }, orders);
                          toggleSubmitting(false);
                        };

                        return (
                          <Fragment>
                            {base}
                            <AppButton
                              color="primary"
                              outline
                              onClick={onSubmit}
                              disabled={isUnsubmittable || isSubmitting}
                            >
                              すべての注文履歴の利用者情報を変更する
                            </AppButton>
                          </Fragment>
                        );
                      },
                    };
                  }}
                >
                  利用者情報変更
                </ModalButton>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            {order.isPreOrder && <div className="alert alert-info">この注文は予約注文です</div>}
            <table className="table table-bordered">
              <tbody className="thead-light">
                <tr>
                  <th style={{ width: 200 }}>注文番号</th>
                  <td>{order.id}</td>
                </tr>
                <tr>
                  <th style={{ width: 200 }}>注文日時</th>
                  <td>{formatDate(order.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                </tr>
                <tr>
                  <th style={{ width: 200 }}>商品</th>
                  <td>
                    {order.orderItems.map((orderItem) => {
                      const { productId, quantity } = orderItem;
                      const product = productsById[productId];
                      return (
                        product != null && (
                          <div>
                            <span>{product.name}</span>
                            <span className="ml-2">{numeral(quantity).format('0,0')}個</span>
                          </div>
                        )
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: 200 }}>合計金額(税込)</th>
                  <td className="text-right">{numeral(order.amount).format('0,0')}</td>
                </tr>
                {order.discountAmount > 0 && (
                  <tr>
                    <th style={{ width: 200 }}>割引額(税込)</th>
                    <td className="text-right">{numeral(order.discountAmount).format('0,0')}</td>
                  </tr>
                )}
                <tr>
                  <th style={{ width: 200 }}>送料(税込)</th>
                  <td className="text-right">{numeral(order.shipmentFee).format('0,0')}</td>
                </tr>
                <tr>
                  <th style={{ width: 200 }}>お支払合計(税込)</th>
                  <td className="text-right">
                    {numeral(order.amount - order.discountAmount + order.shipmentFee).format('0,0')}
                  </td>
                </tr>
                {entries({ ...omit({ ...fields(), ...(order.isWish && payerFields()), }) }).map(([fieldName, fieldSetting]) => {
                  const { label } = fieldSetting;
                  const hides = fieldName === 'couponId' && isEmpty(order.couponId);
                  const prefix = fieldName.startsWith('destination')
                    ? '配送先'
                    : fieldName.startsWith('contactor')
                    ? '利用者情報'
                    : fieldName.startsWith('payer')
                    ? '決済者情報'
                    : '';
                  return (
                    !hides && (
                      <tr key={fieldName}>
                        <th style={{ width: 200 }}>
                          {prefix}
                          {label}
                        </th>
                        <td style={{ whiteSpace: 'pre-line' }}>{fieldDisplayValue(order[fieldName], fieldSetting)}</td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <AppButton className="cancel" color="secondary" onClick={toggleModal.bind(null, false)}>
              閉じる
            </AppButton>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
}

function CancelModalContent(props) {
  const { toggleModal, order, texts, user } = props;
  const [showsCancelButton, toggleCancelButton] = useToggle(true);
  const statedFields = useFormState({}, cancelRequestFields());
  const { instruction, endsForm } = reasonsByLael[statedFields.reason.value] || {};
  const onClickCancel = async () => {
    if (!window.confirm('本当にキャンセルしますか？')) return;

    if (order.cammacsStatus === 'initial') {
      await order.ref.update({ cammacsStatus: 'cancelled' });
      toast.success('キャンセルしました');
    } else {
      const { data } = await cancelOrder({ orderId: order.id });
      if (data.status === 'OK') {
        toast.success('キャンセルしました');
        toggleModal(false);
      } else {
        console.log(data);
        toggleCancelButton(false);
      }
    }
  };
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;

    const message = dedent`${get(texts, 'orderCancelConfirmMessageText', '')}
    キャンセル or 内容変更を送信しますか？
    `;
    if (!window.confirm(message)) return;

    toggleSubmitting(true);
    await cancelRequestsRef.doc().set({
      ...mapValues(statedFields, 'value'),
      orderId: order.id,
      orderItems: order.orderItems,
      status: 'initial',
      createdAt: new Date(),
      createdBy: user,
    });
    toast.success('キャンセル or 内容変更をリクエストしました');
    toggleSubmitting(false);
    toggleModal(false);
  };

  return (
    <div>
      <div className="card border-danger text-danger p-3 mb-3">
        内容変更を希望する場合、注文キャンセルの上、再注文をお願いします。
      </div>
      {showsCancelButton ? (
        <ProgressButton className="rounded-pill px-4" color="primary" block process={onClickCancel}>
          キャンセルする
        </ProgressButton>
      ) : (
        <Form onSubmit={onSubmit}>
          <div className="alert alert-danger ">
            キャンセルを試みましたが、すでに出荷準備中のためできませんでした。
            <br />
            お手数ですが、以下よりキャンセル希望のリクエストをお願いします。
          </div>
          <Field name="reason" {...statedFields.reason} />
          {!isEmpty(statedFields.reason.value) && (
            <div>
              {instruction != null && (
                <div className="alert alert-info" style={{ whiteSpace: 'pre-line' }}>
                  {instruction}
                </div>
              )}
              {!endsForm && (
                <Fragment>
                  <Field name="note" {...statedFields.note} />
                  <Button
                    color="primary"
                    type="submit"
                    block
                    className="rounded-pill px-4"
                    disabled={isUnsubmittable || isSubmitting}
                  >
                    送信する
                  </Button>
                </Fragment>
              )}
            </div>
          )}
        </Form>
      )}
    </div>
  );
}
